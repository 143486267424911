import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59aef29a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "hero-event_container",
  ref: "el"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "event-details" }
const _hoisted_4 = { class: "detail" }
const _hoisted_5 = { class: "detail" }
const _hoisted_6 = { class: "event-btn" }
const _hoisted_7 = { class: "event-image" }
const _hoisted_8 = ["lazy-src", "alt"]
const _hoisted_9 = { class: "event-cta" }
const _hoisted_10 = ["href", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bookmark_button = _resolveComponent("bookmark-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      class: "event-body",
      href: _ctx.url,
      onClick: _cache[2] || (_cache[2] = () => _ctx.$emit('clicked'))
    }, [
      _createElementVNode("div", {
        class: "event-bg-color",
        style: _normalizeStyle(`background-color: ${_ctx.backgroundColour}; color: ${_ctx.textColour};`)
      }, null, 4),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.name), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.when), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.where), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_bookmark_button, {
          label: "bookmark event",
          "label-bookmarked": "unbookmark event",
          "is-bookmarked": _ctx.isBookmarked,
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('bookmark', $event)), ["prevent"]))
        }, null, 8, ["is-bookmarked"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("img", {
          ref: "eventImage",
          src: "/images/placeholder.png",
          "lazy-src": _ctx.image,
          onerror: "this.onerror=null;this.src='/images/placeholder.png'",
          alt: `Poster art for ${_ctx.name}`
        }, null, 8, _hoisted_8)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("a", {
          class: "primary-button",
          href: _ctx.url,
          onClick: _cache[1] || (_cache[1] = () => _ctx.$emit('clicked')),
          title: `Buy tickets to ${_ctx.name}`
        }, "Buy now", 8, _hoisted_10)
      ])
    ], 8, _hoisted_2)
  ], 512))
}

import { OztixEvent } from "@/domain/types";
import { defineComponent } from "vue";
import { sampleEvents } from "./sampleEvents";
import PrimarySection from "@/components/Section/PrimarySection.vue";

export default defineComponent({
  name: "BaseDataCaptureSection",
  components: {
    PrimarySection,
  },
  props: {
    heading: {
      type: String,
      required: true,
      default: "Heading",
    },
  },
  data() {
    return {
      columnCount: 0, // initially
      containerWidth: 0, // initially
    };
  },
  computed: {
    displayedEvents(): Array<OztixEvent> {
      return sampleEvents.slice(0, this.columnCount);
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateColumnCount);
    this.$nextTick(this.updateColumnCount);

    // remove child elements from tab order so keyboard-users cannnot get to sample events
    this.$nextTick(() => {
      this.$nextTick(() => {
        // need to wait 2 ticks for elements to be available
        const childAnchors = document.querySelectorAll(
          ".data-capture-section__background a"
        );
        childAnchors.forEach((a) => {
          (a as HTMLAnchorElement).tabIndex = -1;
        });
        const childButtons = document.querySelectorAll(
          ".data-capture-section__background button"
        );
        childButtons.forEach((b) => {
          (b as HTMLButtonElement).tabIndex = -1;
        });
      });
    });
  },
  methods: {
    updateColumnCount() {
      const newWidth = this.getContainerWidth();
      this.containerWidth = newWidth;

      if (newWidth <= 437) {
        this.columnCount = 2;
        return;
      }

      if (newWidth <= 537) {
        this.columnCount = 3;
        return;
      }

      if (newWidth <= 637) {
        this.columnCount = 4;
        return;
      }

      if (newWidth > 637) {
        this.columnCount = 5;
        return;
      }
    },
    getContainerWidth() {
      return this.$refs.container
        ? (this.$refs.container as HTMLElement).clientWidth
        : 0;
    },
  },
});

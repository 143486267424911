
import { defineComponent } from "vue";
import { scrollFix } from "@/library/focusUtil";

export default defineComponent({
  name: "SkipLink",
  props: {
    hash: {
      type: String,
    },
  },
  setup() {
    return { scrollFix };
  },
});

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faInstagram,
  faFacebook,
  faTwitter,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons'
import {
  faThumbsUp,
  faThumbsDown,
  faBell,
  faSearch,
  faSearchPlus,
  faLocationArrow,
  faHeart,
  faHotel,
  faCalendar,
  faDrum,
  faTheaterMasks,
  faExternalLinkSquareAlt,
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faInstagram,
  faFacebook,
  faTwitter,
  faLinkedin,
  faThumbsUp,
  faThumbsDown,
  faBell,
  faSearch,
  faSearchPlus,
  faLocationArrow,
  faHeart,
  faHotel,
  faCalendar,
  faDrum,
  faTheaterMasks,
  faExternalLinkSquareAlt,
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
)

export type PromotionContent = {
	textDescription: string;
	imgSrc: string;
	url: string;
	id: string;
};

export const CURRENT_PROMOTIONS = [
	{
		textDescription: '$100 off your first case of wine at Naked Wines',
		imgSrc: require('@/assets/promoted/naked-wines-001-rs.png'),
		url: 'https://www.nakedwines.com.au/ztix100',
		id: 'naked-wines-001',
	},
	{
		textDescription: "Escape to K'Gari (Fraser Island) with Sunrover",
		imgSrc: require('@/assets/promoted/sunrover-tours-001-rs.png'),
		url:
			'https://www.sunrover.com.au/?utm_source=oztix&utm_medium=homepage&utm_content=displayad',
		id: 'sunrover-camping-001',
	},
];

export const BACKUP_PROMOTIONS = [
	{
		textDescription: '$100 off your first case of wine at Naked Wines',
		imgSrc: require('@/assets/promoted/naked-wines-001-rs.png'),
		url: 'https://www.nakedwines.com.au/ztix100',
		id: 'naked-wines-001',
	},
	{
		textDescription: "Escape to K'Gari (Fraser Island) with Sunrover",
		imgSrc: require('@/assets/promoted/sunrover-tours-001-rs.png'),
		url:
			'https://www.sunrover.com.au/?utm_source=oztix&utm_medium=homepage&utm_content=displayad',
		id: 'sunrover-camping-001',
	},
];


import { defineComponent, PropType } from "vue";
import { isValidUrl, isValidEventStatus } from "@/library/validationUtil";
import { EventStatus } from "@/domain/types";
import { BookmarkButton } from "@/components";

export default defineComponent({
  name: "SearchEvent",
  components: {
    BookmarkButton,
  },
  emits: ["viewed", "bookmark", "clicked"],
  props: {
    eventKey: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
      validator: (url) => isValidUrl(url),
    },
    day: {
      type: String,
      default: () => "",
      required: true,
    },
    date: {
      type: String,
      default: () => "",
      required: true,
    },
    where: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "/images/placeholder.png",
      validator: (image) => isValidUrl(image),
    },
    status: {
      type: String as PropType<EventStatus>,
      default: null,
      validator: (status) => isValidEventStatus(status),
    },
    isBookmarked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      observer: null as null | IntersectionObserver,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      threshold: [0, 0.9],
    });

    this.$nextTick(() => {
      if (
        this.$refs.el instanceof Element &&
        this.observer instanceof IntersectionObserver
      ) {
        this.observer.observe(this.$refs.el);
      }
    });
  },
  methods: {
    handleIntersection(entries: IntersectionObserverEntry[]): void {
      for (const { isIntersecting, intersectionRatio } of entries) {
        if (!isIntersecting) {
          return;
        }

        if (intersectionRatio > 0.8) {
          this.$emit("viewed");
        }
      }
    },
  },
});

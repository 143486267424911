import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c5ab358"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "event-image" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "event-details" }
const _hoisted_5 = { class: "detail" }
const _hoisted_6 = { class: "detail" }
const _hoisted_7 = { class: "event-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bookmark_button = _resolveComponent("bookmark-button")!

  return (_openBlock(), _createElementBlock("a", {
    class: "primary-event_container",
    href: _ctx.url,
    ref: "el"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.image,
        alt: `Poster for ${_ctx.name}`,
        onerror: "this.onerror=null;this.src='/images/placeholder.png'"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.name), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.when), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.where), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_bookmark_button, {
        label: "bookmark event",
        "label-bookmarked": "unbookmark event",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('bookmark', $event)), ["prevent"])),
        "is-bookmarked": _ctx.isBookmarked
      }, null, 8, ["is-bookmarked"])
    ])
  ], 8, _hoisted_1))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a795fdf"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "href"]
const _hoisted_2 = {
  class: "input-icon-left",
  "aria-hidden": "true"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_search_box = _resolveComponent("search-box")!

  return (_openBlock(), _createBlock(_component_search_box, {
    modelValue: _ctx.currentRefinement,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentRefinement) = $event)),
    onSubmit: _ctx.onSearchSubmit,
    hits: _ctx.hits
  }, {
    default: _withCtx(({ hit }) => [
      _createElementVNode("a", {
        id: hit.id,
        href: _ctx.createURL(hit.textLabel, hit.type),
        class: "hit"
      }, [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_component_font_awesome_icon, {
            icon: hit.faIcon
          }, null, 8, ["icon"])
        ]),
        _createElementVNode("span", {
          class: "highlight",
          innerHTML: hit.highlightHtml
        }, null, 8, _hoisted_3),
        _createElementVNode("span", _hoisted_4, _toDisplayString(hit.textLabel) + ", " + _toDisplayString(hit.type), 1)
      ], 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["modelValue", "onSubmit", "hits"]))
}
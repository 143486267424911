export type StaffProfile = {
    name: string;
    position: string;
    image: string;
    imageDescription: string;
    quote: string;
}

export const profiles = {
    value: [
        {
            "name": "Seth Clancy",
            "position": "Commercial Director",
            "image": "staff-seth-clancy.jpg",
            "imageDescription": "",
            "quote": "With over 30 years' experience in the entertainment industry, Seth is an experienced Commercial Director, leading strategy, sales and execution for some of the world's biggest brands including Coca-Cola, British Airways, Sony Music, Vodafone, Apple and Foxtel's Channel [V] and MAX. Seth's tireless support and drive to ensure clients are getting the most value from their offering is why our clients remain so loyal to Oztix.",
        },
        {
            "name": "Brad Hinds",
            "position": "Head of Music",
            "image": "staff-brad-hinds.jpg",
            "imageDescription": "",
            "quote": "What Brad doesn't know about the events and music industry is not worth knowing. He is not just a BDM to our client, he is the one providing advice, guidance and just general support through the good times and the bad. The vast majority of Brad's time is spent speaking to clients strategising with them on how to make their event more successful, or putting together an event or line-up that's going to shoot the lights out.",
        },
        {
            "name": "Brian Chladil",
            "position": "Director and Founder",
            "image": "staff-brian-chladil.jpg",
            "imageDescription": "",
            "quote": "With 30+ years' experience working in all facets of the music business including event manager and promoter, Brian has a high level of industry knowledge, a vast network of industry contacts and understanding of client needs. Brian has run 120+ outdoor events nationally, over 22 years, including Soundwave Festival and Big Day Out Queensland from 1994 to 2014.",
        },
        {
            "name": "Stuart Field",
            "position": "Director and Founder",
            "image": "staff-stuart-field.jpg",
            "imageDescription": "",
            "quote": "With a background in event management, Stuart has been responsible for large scale touring festivals such as Big Day Out, Soundwave and Splendour in the Grass. Since founding Oztix in 2003, Stu has evolved the business strategy, effectively managed a national team, and continuously innovated technology that has grown Oztix from a small tech start up to Australia’s largest independent ticketing company.",
        },
        {
            "name": "Steve Tassone",
            "position": "General Manager",
            "image": "staff-steve-tassone.jpg",
            "imageDescription": "",
            "quote": "Coming from a background in the financial services industry Steve has extensive experience in people management, spending most of his career building effective collaboration and execution amongst teams. Since coming on board to Oztix in 2019, he has utilised his expertise to lead the strategic direction of the business, and will continue to do so over the coming months.",
        },
        {
            "name": "Erin Nolan",
            "position": "Head of Partnerships",
            "image": "staff-erin-nolan.jpg",
            "imageDescription": "",
            "quote": "With an ability to understand our clients and to often know what they want before they do, Erin has displayed the ability to drive the business to help deliver strategic pieces of work. Her focus, as Head of Partnerships is to ensure that our clients receive support in the areas that are going to have the most impact on their success.",
        },
        {
            "name": "Owen Banks",
            "position": "Head of Innovation",
            "image": "staff-owen-banks.jpg",
            "imageDescription": "",
            "quote": "Owen has been with the business for almost as long as it has existed – having worked in nearly every role there is. He knows and understands the core Oztix system, he should, considering that he designed most of it! He has the ultimate “can do” solutions-focused attitude that ensures everything that he touches runs seamlessly.",
        }
    ] as StaffProfile[]
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "badge-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_button = _resolveComponent("close-button")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("li", {
        key: item.key,
        class: "badge"
      }, [
        _createElementVNode("span", null, _toDisplayString(item.name), 1),
        _createVNode(_component_close_button, {
          label: `Remove ${item.name}`,
          onClick: ($event: any) => (_ctx.$emit('removeItem', item))
        }, null, 8, ["label", "onClick"])
      ]))
    }), 128))
  ]))
}

import { defineComponent } from "vue";
import { useHead } from "@vueuse/head";
import { api } from "@/api";
import { Loading } from "@/components";
import {
  DeveloperOverrides,
  RecommendationsOptions,
  RecommendationsResponse,
  LocationType,
  RecommendationsEngineOptions,
  UserProfile
} from "@/api/types";
import { Report } from "@/components/DevReporter/types";
import { checkOztixDeveloperCookie } from "@/library/cookieUtil";

type EventRow = {
  i: number;
  eventKey: number;
  eventName: string;
  eventVenue: string;
  eventCategories: string;
  eventScore: number;
};

type RecurringEventRow = {
  eventKey: number;
  eventName?: string;
  appearances: Array<{ section: string; order: number }>;
};

export default defineComponent({
  components: {
    Loading
  },
  data() {
    return {
      hasDeveloperCookie: false,
      isLoading: false,
      form: {
        trackingId: "",
        customerId: "",
        postcode: "",
        latitude: "",
        longitude: "",
        presetGeo: "",
        use: LocationType.None
      },
      report: {
        hasData: false,
        options: {
          geo: { latitude: 0, longitude: 0 },
          postcode: "",
          use: LocationType.None
        } as RecommendationsEngineOptions,
        customer: {
          trackingId: ""
        },
        summary: {
          sections: []
        }
      } as Report,
      recommendations: null as RecommendationsResponse | null,
      userProfile: null as UserProfile | null,
      showCustomerEventsTable: true as boolean,
      showEventsTable: [] as Array<boolean>,
      showEventStatisticsTable: true as boolean,
      maxScore: 0,
      locationType: LocationType
    };
  },
  mounted() {
    useHead({
      title: "Dev Reporter — Oztix",
      meta: [
        {
          name: "description",
          content:
            "Oztix is the home of personalised experiences. Find the perfect event just for you."
        },
        {
          name: "robots" /* do not crawl/index */,
          content: "noindex"
        }
      ]
    });
    this.hasDeveloperCookie = checkOztixDeveloperCookie();
  },
  watch: {
    "form.presetGeo"() {
      var latLng = this.form.presetGeo.split(",");
      this.form.latitude = latLng[0];
      this.form.longitude = latLng[1];
    },
    "form.latitude"() {
      this.form.use = LocationType.Geo;
    },
    "form.longitude"() {
      this.form.use = LocationType.Geo;
    },
    "form.postcode"() {
      this.form.use = LocationType.Postcode;
    }
  },
  computed: {
    topRecurringEvents(): Array<RecurringEventRow> {
      const topEvents = [] as Array<RecurringEventRow>;

      this.recommendations?.sections.forEach(s => {
        s.events.forEach((e, i) => {
          const foundIndex = topEvents.findIndex(
            re => re.eventKey === e.eventKey
          );
          if (foundIndex > -1) {
            topEvents[foundIndex].appearances.push({
              section: s.title,
              order: i + 1
            });
          } else {
            topEvents.push({
              eventKey: e.eventKey,
              eventName: this.recommendations?.catalog.find(
                catEv => catEv.eventKey == e.eventKey
              )?.eventName,
              appearances: [
                {
                  section: s.title,
                  order: i + 1
                }
              ]
            });
          }
        });
      });

      return topEvents.sort((e1, e2) => {
        return this.homogeneityScore(e1) - this.homogeneityScore(e2);
      });
    },
    fullyHydratedSections(): Array<{
      sectionName: string;
      events: Array<EventRow>;
    }> {
      const sections = [] as Array<{
        sectionName: string;
        events: Array<EventRow>;
      }>;

      this.recommendations?.sections.forEach(s => {
        const events = [] as Array<EventRow>;

        s.events.forEach((ev, i) => {
          const catEv = this.recommendations?.catalog.find(
            ce => ce.eventKey == ev.eventKey
          );
          events.push({
            i,
            eventKey: ev.eventKey,
            eventName: catEv?.eventName,
            eventVenue: `${catEv?.venue.name}, ${catEv?.venue.locality} (${catEv?.venue.state})`,
            eventCategories: `(${
              catEv?.categories.length
            }) ${catEv?.categories.toString().replaceAll(",", ", ")}`,
            eventScore: ev.score
          } as EventRow);
        });

        sections.push({
          sectionName: s.title,
          events
        });
      });

      return sections;
    }
  },
  methods: {
    toggleShowEventsTable(i: number) {
      this.showEventsTable[i] = !this.showEventsTable[i];
    },
    toggleEventStatisticsTable() {
      this.showEventStatisticsTable = !this.showEventStatisticsTable;
    },
    async handleSubmit() {
      this.isLoading = true;

      const latitude = parseFloat(this.form.latitude || "0");
      const longitude = parseFloat(this.form.longitude || "0");
      const options = {
        geo: { latitude: latitude, longitude: longitude },
        postcode: this.form.postcode,
        use: this.form.use,
        customerId: this.form.customerId
      } as RecommendationsOptions;

      const developerOverrides = {
        trackingId: this.form.trackingId
      } as DeveloperOverrides;

      const recommendationsResponse = await api.recommendation.postRecommendations(
        options,
        developerOverrides
      );

      const userResponse = await api.developer.getUserProfile(
        this.form.trackingId,
        this.form.customerId
      );

      this.recommendations = recommendationsResponse.data;
      this.userProfile = userResponse.data.userProfile;
      this.report = this.generateReport(recommendationsResponse.data);

      this.showEventsTable = new Array(
        recommendationsResponse.data.sections.length
      ).fill(true);

      this.maxScore = this.report.summary.sections.reduce(
        (prev, cur) => Math.max(prev, cur.maxScore),
        0
      );

      this.isLoading = false;
    },
    generateReport(recommendations: RecommendationsResponse): Report {
      let sections = recommendations.sections.map(s => ({
        title: s.title,
        id: s.id,
        eventCount: s.events.length,
        maxScore: s.events.reduce((prev, cur) => Math.max(prev, cur.score), 0)
      }));

      return {
        hasData: true,
        options: recommendations.options,
        customer: {
          trackingId: recommendations.trackingId
        },
        summary: {
          sections
        }
      } as Report;
    },
    homogeneityScore(row: RecurringEventRow): number {
      const orderMap = new Map();
      row.appearances.forEach(a => {
        const orderValue = orderMap.get(a.order);
        if (orderValue) {
          orderMap.set(a.order, orderValue + 1);
        } else {
          orderMap.set(a.order, 1);
        }
      });

      let score = 0;
      let maxCount = 1;
      orderMap.forEach(count => {
        if (count > maxCount) maxCount = count;
      });

      orderMap.forEach((_, order) => {
        score +=
          order /
          Math.pow(this.recommendations?.catalog.length || 3000, maxCount);
      });

      return score;
    }
  }
});

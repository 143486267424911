import { createApp } from 'vue'
import App from './App.vue'
import { createHead } from '@vueuse/head';
import router from './router'
import 'normalize.css'
import './styles/app.scss'
import './styles/icons.ts'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import store from '@/store'
import InstantSearch from 'vue-instantsearch/vue3/es';
import { imagesLoaded, clickOutside } from '@/directives';
import { FocusTrap } from 'focus-trap-vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import posthog from "./analytics/posthog"

const app = createApp(App)

// for adding <head> info for SEO and a11y
// usage: https://github.com/vueuse/head
const head = createHead()
app.use(head)

// add router
app.use(router)

// add posthog
app.use(posthog)

// add state management
app.use(store)

// add Algolia Instant Search
app.use(InstantSearch)

// register Font Awesome component globally
app.component('font-awesome-icon', FontAwesomeIcon)
app.directive('images-loaded', imagesLoaded)
app.directive('click-outside', clickOutside)

// focus trapping
app.component('focus-trap', FocusTrap)

// toast notification
app.use(VueToast, {
    position: 'top'
})

// mount Vue instance (last)
app.mount('#app')


import { defineComponent } from "vue";

export default defineComponent({
  name: "Copyright",
  props: {
    align: {
      type: String,
      required: false,
      default: "center",
    },
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26b65d11"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "container",
  class: "data-capture-section__container"
}
const _hoisted_2 = {
  ref: "background",
  class: "data-capture-section__background",
  "aria-hidden": "true"
}
const _hoisted_3 = { class: "data-capture-section__contents" }
const _hoisted_4 = { "aria-hidden": "true" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_primary_section = _resolveComponent("primary-section")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_primary_section, {
        key: _ctx.displayedEvents.length,
        events: _ctx.displayedEvents,
        "do-not-track": true
      }, null, 8, ["events"])
    ], 512),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
      ]),
      _createElementVNode("h2", null, _toDisplayString(_ctx.heading), 1),
      _renderSlot(_ctx.$slots, "contents", {}, undefined, true)
    ])
  ], 512))
}

import { defineComponent } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { string } from "yup";
import {
  SearchResponse,
  MultipleQueriesQuery,
} from "@algolia/client-search/dist/client-search";
import algoliasearch from "algoliasearch/lite";
import config from "@/config";
import { SearchBox } from "@/components";
import { AlgoliaEvent } from "../Search/types";
import moment from "moment";
import { web2Case } from "./web2Case";

type RequestType = {
  value: string;
  label: string;
  options: { value: string; label: string }[] | null;
  relatedToEvent: boolean;
};

const requestTypes: RequestType[] = [
  {
    value: "companion card_accessibility",
    label: "Companion Card/Accessibility",
    options: [
      {
        value: "Companion Card",
        label: "Companion Card",
      },
      {
        value: "Disability Access",
        label: "Disability Access",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
    relatedToEvent: true,
  },
  {
    value: "customer account changes",
    label: "Customer Account Changes",
    options: [
      {
        value: "Update Email Address",
        label: "Update Email Address",
      },
      {
        value: "Unsubscribe Request",
        label: "Unsubscribe Request",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
    relatedToEvent: false,
  },
  {
    value: "event inquiry",
    label: "Event Inquiry",
    options: [
      { value: "Cancelled Event", label: "Cancelled Event" },
      { value: "Event Information", label: "Event Information" },
      { value: "Postponed Event", label: "Postponed Event" },
      { value: "Relocated Event", label: "Relocated Event" },
      { value: "Rescheduled Event", label: "Rescheduled Event" },
      { value: "Other", label: "Other" },
    ],
    relatedToEvent: true,
  },
  {
    value: "order inquiry",
    label: "Order Inquiry",
    options: [
      {
        value: "Changed to Eticket",
        label: "Changed to Eticket",
      },
      {
        value: "Incorrect Address / Email",
        label: "Incorrect Address / Email",
      },
      {
        value: "Resend Delivery Tracking",
        label: "Resend Delivery Tracking",
      },
      { value: "Resend Eticket(s)", label: "Resend Eticket(s)" },
      {
        value: "Tickets Not Received",
        label: "Tickets Not Received",
      },
      { value: "Unable to Find", label: "Unable to Find" },
      { value: "Pay Over Time", label: "Pay Over Time" },
      { value: "Track and Trace", label: "Track and Trace" },
      { value: "Other", label: "Other" },
    ],
    relatedToEvent: true,
  },
  {
    value: "refunds",
    label: "Refunds",
    options: [
      { value: "Cancelled Event", label: "Cancelled Event" },
      { value: "Follow Up Request", label: "Follow Up Request" },
      {
        value: "Manual Refund Request",
        label: "Manual Refund Request",
      },
      { value: "Postponed Event", label: "Postponed Event" },
      { value: "Relocated Event", label: "Relocated Event" },
      { value: "Rep/Ref", label: "Rep/Ref" },
      { value: "Rescheduled Event", label: "Rescheduled Event" },
      { value: "Wrong Date", label: "Wrong Date" },
      { value: "Wrong Show", label: "Wrong Show" },
      { value: "Wrong Ticket Type", label: "Wrong Ticket Type" },
      { value: "Other", label: "Other" },
    ],
    relatedToEvent: true,
  },
  {
    value: "non-oztix inquiry",
    label: "Non-Oztix Inquiry",
    options: null,
    relatedToEvent: false,
  },
];

export default defineComponent({
  components: {
    Form,
    Field,
    ErrorMessage,
    SearchBox,
  },
  data() {
    const formData = {
      customerName: "",
      email: "",
      subject: "",
      requestType: "",
      requestReason: "",
      relatedEvent: "",
      description: "",
    };

    const validation = {
      customerName: string().required(),
      email: string().required().email(),
      subject: string().required(),
      requestType: string().required(),
      requestReason: string().required(),
      description: string().required(),
    };

    const relatedEvent = {
      eventSearchClient: algoliasearch(
        config.ALGOLIA_APPLICATION_ID,
        config.ALGOLIA_PERSONALISATION_API_KEY
      ),
      hits: [] as AlgoliaEvent[],
      currentRefinement: "",
    };

    return {
      validation,
      formData,
      requestTypes,
      relatedEvent,
    };
  },
  computed: {
    currentRequestType(): RequestType | undefined {
      const requestType = this.requestTypes.find(
        (req) => req.value === this.formData.requestType
      );
      return requestType;
    },
    showRequestReason(): boolean {
      if (!this.currentRequestType) return false;
      return (
        this.currentRequestType &&
        this.currentRequestType.options !== null &&
        this.currentRequestType.options.length > 0
      );
    },
    requestReasons(): { value: string; label: string }[] {
      if (!this.currentRequestType || this.currentRequestType.options === null)
        return [];
      return this.currentRequestType.options;
    },
    showEventSearch(): boolean {
      if (!this.currentRequestType) return false;
      if (!this.formData.requestReason || this.formData.requestReason === "")
        return false;
      return this.currentRequestType.relatedToEvent;
    },
  },
  watch: {
    "formData.requestType"() {
      this.formData.requestReason = "";
    },
    "relatedEvent.currentRefinement"(val: string) {
      const queries = [
        {
          indexName: "prod_oztix_eventguide",
          query: val,
          params: {
            hitsPerPage: 5,
          },
        },
      ] as MultipleQueriesQuery[];

      this.relatedEvent.hits = [];

      this.relatedEvent.eventSearchClient
        .search<AlgoliaEvent>(queries)
        .then(({ results }) => {
          const eventGuideResults = results[0] as SearchResponse<AlgoliaEvent>;

          const hits = eventGuideResults.hits;

          for (let i = 0; i < hits.length; i++) {
            this.relatedEvent.hits.push(hits[i]);
          }
        });
    },
  },
  methods: {
    formatEventDescription(suggestion: AlgoliaEvent) {
      var eventDateInVenueTZ = moment
        .tz(suggestion.DateStart, "UTC")
        .tz(suggestion.Venue.Timezone)
        .format("DD/MM/YYYY hh:mm A");

      return (
        suggestion.EventName +
        " at " +
        suggestion.Venue.Name +
        " on " +
        eventDateInVenueTZ
      );
    },
    onSearchSubmit(val: unknown): void {
      /* does not need to submit - instead uses v-model to set "currentRefinement" */
    },
    selectRelatedEvent(val: string): void {
      this.relatedEvent.currentRefinement = val;
      this.formData.relatedEvent = val;
    },
    onSubmit(): void {
      let subject = this.formData.subject;

      if (this.formData.relatedEvent && this.formData.relatedEvent.length > 0) {
        subject += " - " + this.formData.relatedEvent;
      }

      const payload = {
        orgid: config.SALESFORCE_WEB_2_CASE_ORG_ID,
        recordType: config.SALESFORCE_WEB_2_CASE_RECORD_TYPE,
        retURL: config.SALESFORCE_WEB_2_CASE_RET_URL,
        Brand__c: config.SALESFORCE_WEB_2_CASE_BRAND,
        // debug: "1",
        // debugEmail: "larenelg@oztix.com.au",
        name: this.formData.customerName,
        email: this.formData.email,
        type: this.formData.requestType,
        Case_Reason__c: this.formData.requestReason,
        selectedEvent: this.formData.relatedEvent,
        subject,
        description: this.formData.description,
      };

      if (process.env.NODE_ENV === "production") {
        web2Case(payload);
      } else {
        // debug
        console.log("web2Case payload", payload);
      }
    },
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_location = _resolveComponent("icon-location")!
  const _component_location_modal = _resolveComponent("location-modal")!
  const _component_base_data_capture_section = _resolveComponent("base-data-capture-section")!

  return (_openBlock(), _createBlock(_component_base_data_capture_section, { heading: "Discover events near you" }, {
    icon: _withCtx(() => [
      _createVNode(_component_icon_location)
    ]),
    contents: _withCtx(() => [
      _createVNode(_component_location_modal, {
        "is-location-set": _ctx.isLocationSet,
        onLocationSetGeo: _cache[0] || (_cache[0] = (e) => _ctx.$emit('locationSetGeo', e)),
        onLocationSetPostcode: _cache[1] || (_cache[1] = (e) => _ctx.$emit('locationSetPostcode', e)),
        onLocationUnset: _cache[2] || (_cache[2] = () => _ctx.$emit('locationUnset'))
      }, null, 8, ["is-location-set"])
    ]),
    _: 1
  }))
}
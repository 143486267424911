
import { defineComponent, PropType } from "vue";
import { Stack, StackItem, PrimaryEvent } from "@/components";
import { OztixEvent } from "@/domain/types";
import { mapState } from "vuex";

export default defineComponent({
  name: "PrimarySection",
  components: {
    Stack,
    StackItem,
    PrimaryEvent,
  },
  emits: ["sectionViewed"],
  props: {
    title: {
      type: String,
      required: true,
    },
    anchorLink: {
      type: String,
      required: false,
      default: "anchor",
    },
    events: {
      type: Array as PropType<Array<OztixEvent>>,
      required: false,
      default: () => [],
    },
    doNotTrack: {
      // do not send data for this component
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showCount: 4, // initially
      columnCount: 2, // initially
      containerWidth: 0, // initially
      displayedEvents: [] as Array<OztixEvent>,
      observer: null as null | IntersectionObserver,
    };
  },
  computed: {
    totalEvents(): number {
      return this.events.length;
    },
    hasMoreEvents(): boolean {
      if (this.showCount == 4 && this.events.length == 5) return false;
      return this.showCount < this.events.length;
    },
    ...mapState("session", ["bookmarkedEventKeys"]),
  },
  events() {
    this.$nextTick(() => {
      this.updateColumnCount();
    });
  },
  mounted() {
    window.addEventListener("resize", this.updateColumnCount);
    for (let i = 0; i < this.showCount; i++) {
      if (i >= this.showCount || i >= this.events.length) break;
      this.displayedEvents.push(this.events[i]);
    }

    if (this.showCount == 4 && this.events.length == 5) {
      this.displayedEvents.push(this.events[4]);
    }

    this.$nextTick(this.updateColumnCount);

    this.observer = new IntersectionObserver(this.handleIntersection, {
      threshold: [0, 0.9],
    });

    this.$nextTick(() => {
      if (
        this.$refs.container instanceof Element &&
        this.observer instanceof IntersectionObserver
      ) {
        this.observer.observe(this.$refs.container);
      }
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.updateColumnCount);
  },
  methods: {
    updateColumnCount() {
      const newWidth = this.getContainerWidth();
      this.containerWidth = newWidth;

      if (newWidth <= 375) {
        this.columnCount = 2;
        return;
      }

      if (newWidth <= 475) {
        this.columnCount = 3;
        return;
      }

      if (newWidth <= 575) {
        this.columnCount = 4;
        return;
      }

      if (newWidth > 575) {
        this.columnCount = 5;
        return;
      }
    },
    getContainerWidth() {
      return this.$refs.container
        ? (this.$refs.container as HTMLElement).clientWidth
        : 0;
    },
    handleShowMoreButton() {
      const currentShowCount = this.showCount;
      this.showCount += 4;
      for (let i = currentShowCount; i < this.showCount; i++) {
        if (i < this.totalEvents) {
          this.displayedEvents.push(this.events[i]);
        }
      }

      this.$nextTick(() => {
        if (!this.doNotTrack) {
          this.$store.dispatch("session/sectionShowMore", {
            interactionTarget: `primary-section--${this.anchorLink}--showCount#${this.showCount}`,
          });
        }
      });
    },
    onEventViewed(eventKey: number, index: number) {
      if (!this.doNotTrack) {
        this.$store.dispatch("session/eventViewed", {
          eventKey: eventKey,
          interactionTarget: `primary-section--${this.anchorLink}--index#${index}`,
        });
      }
    },
    onBookmarkEvent(eventKey: number, index: number) {
      if (!this.doNotTrack) {
        this.$store.dispatch("session/bookmarkEvent", {
          eventKey: eventKey,
          interactionTarget: `primary-section--${this.anchorLink}--index#${index}`,
        });
      }
    },
    onEventClicked(eventKey: number, index: number) {
      if (!this.doNotTrack) {
        this.$store.dispatch("session/eventClicked", {
          eventKey: eventKey,
          interactionTarget: `primary-section--${this.anchorLink}--index#${index}`,
        });
      }
    },
    handleIntersection(entries: IntersectionObserverEntry[]) {
      for (const { isIntersecting, intersectionRatio } of entries) {
        if (!isIntersecting) {
          return;
        }

        if (intersectionRatio > 0.8) {
          if (!this.doNotTrack) {
            this.$emit("sectionViewed");
          }
        }
      }
    },
  },
});


import { defineComponent } from "vue";
import { mapState } from "vuex";
import { SimpleModal } from "./components";

export default defineComponent({
  components: {
    SimpleModal,
  },
  async mounted(): Promise<void> {
    this.$store.dispatch("session/fetchSessionId");
    this.$store.dispatch("session/checkIsDeveloper");
    this.$store.dispatch("session/checkIsDonotfollow");
    this.$store.dispatch("session/fetchTrackingId");
    this.$store.dispatch("session/fetchSessionData");
  },
  computed: { ...mapState("session", ["isDeveloper", "isDonotfollow"]) },
  data() {
    return {
      staffInfoMessage:
        'Your preferences/interactions are not saved to the server while in Staff Mode. To remove Staff Mode, please delete "ot_donotfollow" in your cookies, and refresh the page. This cookie is added whenever you log into Studio, if you are Oztix staff.',
      devInfoMessage:
        'Your preferences/interactions are not saved to the server while in Developer Mode. To remove Developer Mode, please delete "ot_developer" in your cookies, and refresh the page.',
    };
  },
});

/* eslint-disable */

import { DirectiveBinding } from "vue";

type NodeWithClickOutside = {
  clickOutsideEvent: (event: Event) => void;
}

type ClickOutsideNode = NodeWithClickOutside & Node

export default {
  beforeMount(el: ClickOutsideNode, binding: DirectiveBinding) {
    el.clickOutsideEvent = function(event) {
      if (!(el === event.target || el.contains(event.target as Node))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el: ClickOutsideNode) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
}
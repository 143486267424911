
import { defineComponent } from "vue";
import { v4 as uuidv4 } from "uuid";
import { askForLocation } from "@/library/geoUtil";
import { IconLocation } from "@/components";
import BaseModal from "@/components/Modal/BaseModal.vue";

export default defineComponent({
  components: { IconLocation, BaseModal },
  name: "LocationModal",
  props: {
    isLocationSet: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ["locationSetGeo", "locationSetPostcode", "locationUnset"],
  data() {
    return {
      initialFocusId: "use-my-location" + uuidv4(),
      postcode: "",
      invalidPostcode: false,
      geoError: "",
    };
  },
  watch: {
    postcode() {
      // if user changes postcode when there is an error, make error disappear
      if (this.invalidPostcode) {
        this.invalidPostcode = false;
      }
    },
  },
  methods: {
    async handleGeoClick(): Promise<void> {
      try {
        const geoPosition = await askForLocation();
        this.$emit("locationSetGeo", geoPosition);
        this.geoError = "";
      } catch (e: unknown) {
        // eslint-disable-next-line no-undef
        if (e instanceof GeolocationPositionError) {
          console.warn("Geolocation could not be used", e);
          if (e.PERMISSION_DENIED) {
            this.geoError =
              "Could not use your location. Please give permission for oztix.com.au to use your position.";
          } else {
            this.geoError =
              "Could not detect your location. Unknown error occured, please check if your device supports GPS.";
          }
        }
      }
    },
    async handlePostcodeClick(): Promise<void> {
      this.geoError = "";
      this.validatePostcode();
      this.$nextTick(() => {
        if (!this.invalidPostcode) {
          this.$emit("locationSetPostcode", this.postcode);
        }
      });
    },
    validatePostcode() {
      const postcodeRegex = new RegExp("^(0[289][0-9]{2})|([1-9][0-9]{3})$"); // Australian postcodes
      if (!postcodeRegex.test(this.postcode)) {
        this.invalidPostcode = true;
      } else {
        this.invalidPostcode = false;
      }
    },
    removeLocation() {
      this.geoError = "";
      this.$emit("locationUnset");
    },
  },
});

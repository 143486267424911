
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import {
  testimonials,
  ClientTestimonial,
} from "@/content/b2b/client-testimonials";

export default defineComponent({
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      testimonials: testimonials.value as ClientTestimonial[],
    };
  },
});

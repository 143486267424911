
import { defineComponent } from "vue";
import { IconCategory } from "@/components";
import BaseDataCaptureSection from "@/components/Section/BaseDataCaptureSection.vue";
import DataCaptureModal from "@/components/Modal/DataCaptureModal.vue";

export default defineComponent({
  name: "GetCategoriesSection",
  components: {
    IconCategory,
    BaseDataCaptureSection,
    DataCaptureModal,
  },
  emits: ["submitPreferences", "resetPreferences"],
  data() {
    return {
      options: ["Festival", "Music", "Art"],
    };
  },
});

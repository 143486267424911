import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_band = _resolveComponent("icon-band")!
  const _component_data_capture_modal = _resolveComponent("data-capture-modal")!
  const _component_base_data_capture_section = _resolveComponent("base-data-capture-section")!

  return (_openBlock(), _createBlock(_component_base_data_capture_section, { heading: "Events based on your favourite artists" }, {
    icon: _withCtx(() => [
      _createVNode(_component_icon_band)
    ]),
    contents: _withCtx(() => [
      _createVNode(_component_data_capture_modal, {
        "button-label": "Add artist",
        "modal-heading": "Events based on your favourite artists",
        "data-type": "band",
        onSubmitPreferences: _cache[0] || (_cache[0] = (e) => _ctx.$emit('submitPreferences', e)),
        onResetPreferences: _cache[1] || (_cache[1] = () => _ctx.$emit('resetPreferences'))
      })
    ]),
    _: 1
  }))
}
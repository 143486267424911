
import { defineComponent, PropType } from "vue";
import { formatDate, getTime } from "@/library/eventFormatUtil";
import { AlgoliaEvent } from "./types";

export default defineComponent({
  name: "SuggestionItem",
  props: {
    hit: {
      type: Object as PropType<AlgoliaEvent>,
    },
  },
  data() {
    return {
      formatDate: formatDate,
      getTime: getTime,
    };
  },
});

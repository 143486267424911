
import { defineComponent, PropType } from "vue";
import Search from "@/components/Search/Search.vue";
import NavMenu from "@/components/Menu/NavMenu.vue";
import TheFooter from "@/components/Footer/TheFooter.vue";
import { LocationQuery } from "vue-router";
import OztixLogo from "@/components/Logo/OztixLogo.vue";

export default defineComponent({
  name: "Layout",
  components: {
    Search,
    NavMenu,
    TheFooter,
    OztixLogo,
  },
  props: {
    full: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchQuery: {
      type: Object as PropType<LocationQuery>,
      required: false,
      default: () => ({
        q: "",
      }),
    },
  },
});

import { isEventStatus } from "@/domain/types"

/* source: https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative */
function isUrlAbsolute(url: string): boolean {
    if (url.indexOf('//') === 0) { return true; } // URL is protocol-relative (= absolute)
    if (url.indexOf('://') === -1) { return false; } // URL has no protocol (= relative)
    if (url.indexOf('.') === -1) { return false; } // URL does not contain a dot, i.e. no TLD (= relative, possibly REST)
    if (url.indexOf('/') === -1) { return false; } // URL does not contain a single slash (= relative)
    if (url.indexOf(':') > url.indexOf('/')) { return false; } // The first colon comes after the first slash (= relative)
    if (url.indexOf('://') < url.indexOf('.')) { return true; } // Protocol is defined before first dot (= absolute)
    return false; // Anything else must be relative
}

export const isValidUrl = (value: unknown): boolean => {
    if (typeof (value) !== "string") return false

    if (isUrlAbsolute(value)) {
        try {
            // eslint-disable-next-line
            const _ = new URL(value)
            return true
        } catch (error: unknown) {
            return false
        }
    }

    return true
}

export const hasImageExtension = (value: unknown): boolean => {
    if (typeof (value) !== "string") return false
    const imageExtensions = ['png', 'jpeg', 'jpg', 'gif', 'webp', 'tiff', 'tif', 'bmp', 'svg']
    const valueArr = value.split('.')
    let hasImageExtension = false
    imageExtensions.forEach(ext => {
        if (valueArr[valueArr.length - 1].includes(ext)) {
            hasImageExtension = true
        }
    })
    return hasImageExtension
}

export const isValidEventStatus = (value: unknown): boolean => {
    if (typeof(value) !== "string" && typeof(value) !== "object") return false
    if (typeof(value) === "object" && value === null) return true
    if (typeof(value) === "object" && value !== null) return false
    if (value.trim() === "") return true
    if (isEventStatus(value)) return true
    return false
}

import { defineComponent } from "vue";
import { useHead } from "@vueuse/head";
import Layout from "@/layouts/Layout.vue";

export default defineComponent({
  components: {
    Layout
  },
  mounted() {
    useHead({
      title: "Page not found — Oztix",
      meta: [
        {
          name: "description",
          content:
            "Oztix is the home of personalised experiences. Find the perfect event just for you."
        }
      ]
    });
  }
});

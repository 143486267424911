
import { defineComponent, PropType } from "vue";
import { v4 as uuidv4 } from "uuid";
import accessibleAutocomplete from "accessible-autocomplete";

export default defineComponent({
  name: "AutocompleteInput",
  props: {
    id: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    visuallyHideLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      /* reference to use for key */
      type: Array as PropType<Array<{ key: number; name: string }>>,
      required: true,
    },
    actionLabel: {
      type: String,
      required: true,
    },
    source: {
      type: Object as PropType<
        | string
        | ((
            query: string,
            populateResults: (filteredResults: Array<string>) => void
          ) => void)
      >,
      required: true,
    },
  },
  emits: ["actionClicked"],
  data() {
    return {
      autocompleteId: this.id ?? "autocomplete-" + uuidv4(),
      selectedKey: null as null | number,
    };
  },
  mounted() {
    accessibleAutocomplete({
      element: this.$refs.autocompleteDiv,
      id: this.autocompleteId, // To match it to the existing <label>.
      source: this.source,
      displayMenu: "overlay",
      autoselect: true,
      onConfirm: this.onConfirm,
    });
  },
  methods: {
    onConfirm(value: string) {
      if (!value) return;
      if (this.options.length > 0) {
        this.selectedKey = this.options.filter(
          (o) => o.name === value
        )[0].key; /* TODO: what if there are multiple values? gets the first one for now */
      }
    },
    onActionButtonClicked() {
      const input = document.getElementById(this.autocompleteId);
      if (input && input instanceof HTMLInputElement) {
        this.$emit("actionClicked", {
          key: this.selectedKey,
          name: input.value,
        });
        input.value = "";
      }
    },
  },
});

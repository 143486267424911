import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { ref: "container" }
const _hoisted_2 = { class: "hero-section__title" }
const _hoisted_3 = ["id"]
const _hoisted_4 = {
  id: "carousel",
  class: "carousel--container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_location_modal = _resolveComponent("location-modal")!
  const _component_hero_event = _resolveComponent("hero-event")!
  const _component_slide = _resolveComponent("slide")!
  const _component_navigation = _resolveComponent("navigation")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_carousel = _resolveComponent("carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", { id: _ctx.anchorLink }, _toDisplayString(_ctx.title), 9, _hoisted_3),
      _createVNode(_component_location_modal, {
        "is-location-set": _ctx.isLocationSet,
        onLocationSetGeo: _cache[0] || (_cache[0] = (e) => _ctx.$emit('locationSetGeo', e)),
        onLocationSetPostcode: _cache[1] || (_cache[1] = (e) => _ctx.$emit('locationSetPostcode', e)),
        onLocationUnset: _cache[2] || (_cache[2] = () => _ctx.$emit('locationUnset'))
      }, null, 8, ["is-location-set"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_carousel, {
        settings: _ctx.carousel.settings,
        breakpoints: _ctx.carousel.breakpoints,
        autoplay: 10000,
        "wrap-around": true
      }, {
        addons: _withCtx(() => [
          _createVNode(_component_navigation),
          _createVNode(_component_pagination)
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.events.slice(0, _ctx.maxEvents), (event, i) => {
            return (_openBlock(), _createBlock(_component_slide, { key: i }, {
              default: _withCtx(() => [
                _createVNode(_component_hero_event, {
                  eventKey: event.eventKey,
                  name: event.name,
                  url: event.url,
                  when: event.when,
                  where: event.where,
                  image: event.studioImage,
                  status: event.status,
                  "is-bookmarked": 
              _ctx.bookmarkedEventKeys &&
              _ctx.bookmarkedEventKeys.includes(event.eventKey)
            ,
                  onViewed: ($event: any) => (_ctx.onEventViewed(event.eventKey, i)),
                  onBookmark: ($event: any) => (_ctx.onBookmarkEvent(event.eventKey, i)),
                  onClicked: ($event: any) => (_ctx.onEventClicked(event.eventKey, i))
                }, null, 8, ["eventKey", "name", "url", "when", "where", "image", "status", "is-bookmarked", "onViewed", "onBookmark", "onClicked"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["settings", "breakpoints"])
    ])
  ], 512))
}

import { defineComponent } from "vue";
import { IconMusic } from "@/components";
import BaseDataCaptureSection from "@/components/Section/BaseDataCaptureSection.vue";
import DataCaptureModal from "@/components/Modal/DataCaptureModal.vue";

export default defineComponent({
  name: "GetGenresSection",
  components: {
    IconMusic,
    BaseDataCaptureSection,
    DataCaptureModal,
  },
  emits: ["submitPreferences", "resetPreferences"],
  data() {
    return {
      options: ["Pop", "Rap", "80s"],
    };
  },
});

/* 
    Attribution:
   https://github.com/desandro/imagesloaded
   https://github.com/David-Desmaisons/Vue.ImagesLoaded
*/

import { DirectiveBinding } from 'vue'
import imagesLoaded from 'imagesloaded'

function isEqual(firstArray: ContextImage[], secondArray: ContextImage[]): boolean {
    const length = firstArray.length;

    if (length != secondArray.length) {
        return false;
    }

    for (let i = 0; i < length; i++) {
        const first = firstArray[i], second = secondArray[i]
        if ((first.img !== second.img) || (first.src !== second.src)) {
            return false;
        }
    }

    return true;
}

function checkFunction(callBack: () => unknown, message = ''): void {
    if (typeof callBack !== 'function') {
        throw `imageLoaded directive error: objet ${callBack} should be a function ${message}`
    }
}

function registerImageLoaded(imgLoad: ImagesLoaded.ImagesLoaded, binding: DirectiveBinding): void {
    const { value, arg, modifiers } = binding

    if (!arg) {
        checkFunction(value)
        imgLoad.on('always', (inst) => setTimeout(() => value(inst)))
        return
    }

    const hasModifier = !!modifiers && !!Object.keys(modifiers).length
    const keys = hasModifier ? modifiers : value;
    const getCallBack = hasModifier ? () => { return value; } : (key: string) => value[key];

    for (const key in keys) {
        const callBack = getCallBack(key)
        checkFunction(callBack, !hasModifier ? `property ${key} of ${value}` : '')
        if (arg === "on" || arg === "off" || arg === "once") {
            imgLoad[arg](key, (inst, img) => setTimeout(() => callBack(inst, img)))
        }
    }
}

function applyImagesLoaded(el: ImagesLoadedNode, binding: DirectiveBinding): void {
    const newContext: ImagesLoaded.ImagesLoaded = imagesLoaded(el);
    const contextImages = newContext.images.map(img => { return { img: img.img, src: img.img.src } })

    if (el.__imagesLoaded__) {
        const oldcontextImages = el.__imagesLoaded__.context
        if (isEqual(oldcontextImages, contextImages)) {
            return
        }
    }

    registerImageLoaded(newContext, binding)
    Object.assign(el.__imagesLoaded__, { context: contextImages, imageLoaded: newContext })
}

type ContextImage = {
    img: HTMLImageElement;
    src: string;
}

type NodeWithImages = {
    __imagesLoaded__: {
        context: ContextImage[],
        imageLoaded?: ImagesLoaded.ImagesLoaded
    } | null
}

type ImagesLoadedNode = NodeWithImages & ImagesLoaded.ElementSelector & Node

export default {
    beforeMount(el: ImagesLoadedNode): void {
        el.__imagesLoaded__ = { context: [] as ContextImage[] }
    },
    mounted(el: ImagesLoadedNode, binding: DirectiveBinding): void {
        applyImagesLoaded(el, binding)
    },
    updated(el: ImagesLoadedNode, binding: DirectiveBinding): void {
        applyImagesLoaded(el, binding)
    },
    unmounted(el: ImagesLoadedNode): void {
        el.__imagesLoaded__ = null
    }
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a83a52e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-pressed"]
const _hoisted_2 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_heart = _resolveComponent("icon-heart")!

  return (_openBlock(), _createElementBlock("button", {
    ref: "btn",
    "aria-pressed": _ctx.isBookmarked
  }, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.isBookmarked ? _ctx.labelBookmarked : _ctx.label), 1),
    _createVNode(_component_icon_heart, {
      filled: _ctx.isBookmarked,
      "has-focus": _ctx.hasFocus,
      "has-hover": _ctx.hasHover
    }, null, 8, ["filled", "has-focus", "has-hover"])
  ], 8, _hoisted_1))
}
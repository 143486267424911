import { CURRENT_PROMOTIONS, PromotionContent } from '@/content/promotions/current'

export type ContentState = {
    promotions: Array<PromotionContent>;
}

// initial state
const state = (): ContentState => ({
    promotions: CURRENT_PROMOTIONS
})

function shuffle(array: Array<any>) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

const PROMOTIONS_TO_SHOW = 2

const getters = {
    selectedPromotions(state: ContentState): Array<PromotionContent> {
        const selectedPromotions = shuffle(state.promotions.slice(0)) // copy array
        return selectedPromotions.slice(0, PROMOTIONS_TO_SHOW)
    }
}

export default {
    namespaced: true,
    state,
    getters
}

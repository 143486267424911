
import { defineComponent } from "vue";

export default defineComponent({
  name: "Close",
  props: {
    hasFocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasHover: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    className() {
      let className = "close";
      if (this.hasHover) className += " hover";
      if (this.hasFocus) className += " focus";
      return className;
    },
  },
});

import rgbToHsl from 'rgb-to-hsl';

export const rgbToHex = (r: number, g: number, b: number): string => '#' + [r, g, b].map(x => {
    const hex = x.toString(16)
    return hex.length === 1 ? '0' + hex : hex
}).join('')

const HUE_BREAKPOINTS = [{
    hue: 360,
    bgColour: '#c70000',
    textColour: '#fff'
},
{
    hue: 14,
    bgColour: '#c73000',
    textColour: '#fff'
},
{
    hue: 29,
    bgColour: '#c75f00',
    textColour: '#fff'
},
{
    hue: 43,
    bgColour: '#c78f00',
    textColour: '#00151c'
},
{
    hue: 54,
    bgColour: '#c7b300',
    textColour: '#00151c'
},
{
    hue: 61,
    bgColour: '#c3c700',
    textColour: '#00151c'
},
{
    hue: 68,
    bgColour: '#abc700',
    textColour: '#00151c'
},
{
    hue: 79,
    bgColour: '#87c700',
    textColour: '#00151c'
},
{
    hue: 104,
    bgColour: '#34c700',
    textColour: '#00151c'
},
{
    hue: 155,
    bgColour: '#00c773',
    textColour: '#00151c'
},
{
    hue: 173,
    bgColour: '#00c7af',
    textColour: '#00151c'
},
{
    hue: 180,
    bgColour: '#00c7c7',
    textColour: '#00151c'
},
{
    hue: 184,
    bgColour: '#00bbc7',
    textColour: '#00151c'
},
{
    hue: 191,
    bgColour: '#00a3c7',
    textColour: '#00151c'
},
{
    hue: 200,
    bgColour: '#0085c7',
    textColour: '#fff'
},
{
    hue: 210,
    bgColour: '#0063c7',
    textColour: '#fff'
},
{
    hue: 220,
    bgColour: '#0044c7',
    textColour: '#fff'
},
{
    hue: 263,
    bgColour: '#4c00c7',
    textColour: '#fff'
},
{
    hue: 274,
    bgColour: '#7100c9',
    textColour: '#fff'
},
{
    hue: 288,
    bgColour: '#a100c9',
    textColour: '#fff'
},
{
    hue: 299,
    bgColour: '#c300c7',
    textColour: '#fff'
},
{
    hue: 320,
    bgColour: '#c70083',
    textColour: '#fff'
},
{
    hue: 331,
    bgColour: '#c7005f',
    textColour: '#fff'
},
{
    hue: 338,
    bgColour: '#c70048',
    textColour: '#fff'
},
{
    hue: 0, /* same as 360 */
    bgColour: '#c70000',
    textColour: '#fff'
}];

export const computeHeroColours = (r: number, g: number, b: number): { backgroundColour: string, textColour: string } => {
    const hsl: number[] = rgbToHsl(r, g, b)
    const h = hsl[0]
    const l = hsl[2]

    if (l < .2) return { backgroundColour: '#d9efff', textColour: '#fff' }
    if (l > .8) return { backgroundColour: '#002d4d', textColour: '#002d4d' }

    const closestH = HUE_BREAKPOINTS.reduce((a, b) => Math.abs(b.hue - h) < Math.abs(a.hue - h) ? b : a)

    return {
        backgroundColour: closestH.bgColour,
        textColour: closestH.textColour
    }
}
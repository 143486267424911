<template>
  <layout :full="true">
    <article>
      <h2 class="article__title">{{ title }}</h2>
      <div class="article__content" :class="[contentClass]" v-html="htmlContent"></div>
    </article>
  </layout>
</template>

<script>
import { defineComponent } from "vue";
import { useHead } from "@vueuse/head";
import marked from "marked";
import DOMPurify from "dompurify";
import Layout from "@/layouts/Layout.vue";

export default defineComponent({
  name: "StaticContent",
  components: {
    Layout
  },
  props: {
    title: {
      type: String,
      required: true,
      default: "Page not found"
    },
    content: {
      type: String,
      required: true,
      default: () =>
        "Oops! We can't seem to find that page. Try searching for an event?"
    },
    contentClass: {
      type: String,
      required: false,
      default: ""
    }
  },
  mounted() {
    useHead({
      title: `${this.title} — Oztix`,
      meta: [
        {
          name: "description",
          content:
            "Oztix is the home of personalised experiences. Find the perfect event just for you."
        }
      ]
    });
  },
  computed: {
    htmlContent() {
      const dirty = marked(this.content);
      const clean = DOMPurify.sanitize(dirty);
      return clean;
    }
  }
});
</script>

<style lang="scss">
.article {
  &__content {
    padding: var(--margin);
    background-color: var(--white);
    color: var(--black);
    border-radius: var(--border-radius);
  }
}

ol.alpha {
  list-style-type: lower-alpha;
}

ol.roman {
  list-style-type: lower-roman;
}

li {
  line-height: 1.5;
}

// Careers page
.content-career {
  h2 {
    font-size: 28px;
    position: relative;
    margin-top: 0;
    color: #222222;
    margin-bottom: 0;
  }
  h2::after {
    content: "";
    display: block;
    padding-top: 3px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-image: linear-gradient(to right, #0272c2, #ffffff) 1;
    max-width: 1200px;
  }

  h3 {
    font-size: 24px;
    margin: 20px 0 0 0;
    ~p {
      margin-top: 10px;
    }
    ~ul, ~ol {
      margin-top: 10px;
      padding-left: 14px;
    }
  }

  h3:first-of-type {
    margin: 10px 0 0 0;
  }

  strong {
    margin: 15px 0;
    font-weight: 600;
    color: #373737;
    font-size: 15px;
  }

  p, li {
    font-size: 14px;
    color: #373737;
    font-weight: 400;
    max-width: 1100px;
    line-height: 1.8;

  }

  li {
    font-size: 14px;

  }

  p:last-of-type {
    margin-top: 30px;
  }
}

</style>

import { defineComponent } from "vue";
import config from "@/config";
import {
  SearchResponse,
  MultipleQueriesQuery,
} from "@algolia/client-search/dist/client-search";

import algoliasearch from "algoliasearch/lite";
import SearchBox from "./SearchBox.vue";
import qs from "qs";
import { LocationQueryValue } from "vue-router";

type EventHit = {
  objectID: string;
  EventName: string;
  _highlightResult: {
    EventName: {
      value: string; // html string used in v-html
    };
  };
};

type VenueHit = {
  objectID: string;
  Name: string;
  _highlightResult: {
    Name: {
      value: string; // html string used in v-html
    };
  };
};

type FacetHit = {
  value: string;
  highlighted: string; // html string
  count: number;
};

/* we map EventHit, VenueHit, and FacetHit to SearchHit */
type SearchHit = {
  id: string;
  highlightHtml: string;
  textLabel: string;
  type: "event" | "venue" | "artist" | "category";
  faIcon:
    | "calendar"
    | "hotel"
    | "drum"
    | "theater-masks" /* these must be registered in `icons.ts` file */;
};

export default defineComponent({
  name: "Search",
  components: {
    SearchBox,
  },
  props: {
    query: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      autocompleteSearchClient: algoliasearch(
        config.ALGOLIA_APPLICATION_ID,
        config.ALGOLIA_AUTOCOMPLETE_API_KEY
      ),
      eventGuideSearchClient: algoliasearch(
        config.ALGOLIA_APPLICATION_ID,
        config.ALGOLIA_PERSONALISATION_API_KEY
      ),
      currentRefinement: "",
      hits: [] as SearchHit[],
      indices: null as
        | null
        | SearchResponse<{ hits: EventHit[] | VenueHit[] }>[],
      currentSuggestionsVersion: 0,
    };
  },
  watch: {
    currentRefinement(val: string) {
      const currentVersion = ++this.currentSuggestionsVersion;
      const queries = [
        {
          indexName: "prod_autocomplete_event",
          query: val,
          params: {
            hitsPerPage: 5,
          },
        },
        {
          indexName: "prod_autocomplete_venue",
          query: val,
          params: {
            hitsPerPage: 5,
          },
        },
      ] as MultipleQueriesQuery[];

      this.autocompleteSearchClient.search(queries).then(({ results }) => {
        this.hits = [];

        if (currentVersion !== this.currentSuggestionsVersion) {
          return;
        }

        this.indices = results as SearchResponse<{
          hits: EventHit[] | VenueHit[];
        }>[];

        for (let i = 0; i < results.length; i++) {
          const currentIndexResults = results[i] as SearchResponse<
            EventHit | VenueHit
          >;
          if (!currentIndexResults) continue;

          let hitType = "";
          if (currentIndexResults.index === "prod_autocomplete_event")
            hitType = "event";
          if (currentIndexResults.index === "prod_autocomplete_venue")
            hitType = "venue";

          for (let j = 0; j < currentIndexResults.hits.length; j++) {
            let hit = currentIndexResults.hits[j];

            switch (hitType) {
              case "event": {
                this.hits.push({
                  id: hit.objectID,
                  highlightHtml: (hit as EventHit)._highlightResult.EventName
                    .value,
                  textLabel: (hit as EventHit).EventName,
                  type: "event",
                  faIcon: "calendar",
                });
                break;
              }
              case "venue": {
                this.hits.push({
                  id: hit.objectID,
                  highlightHtml: (hit as VenueHit)._highlightResult.Name.value,
                  textLabel: (hit as VenueHit).Name,
                  type: "venue",
                  faIcon: "hotel",
                });
                break;
              }
            }
          }
        }

        const facetIndex = this.eventGuideSearchClient.initIndex(
          "prod_oztix_eventguide"
        );

        facetIndex
          .searchForFacetValues("Bands", val)
          .then(({ facetHits }: { facetHits: FacetHit[] }) => {
            for (let i = 0; i < facetHits.length; i++) {
              let hit = facetHits[i];

              this.hits.push({
                id: hit.value,
                highlightHtml: hit.highlighted,
                textLabel: hit.value,
                type: "artist",
                faIcon: "drum",
              });
            }
          });

        facetIndex
          .searchForFacetValues("Categories", val)
          .then(({ facetHits }) => {
            for (let i = 0; i < facetHits.length; i++) {
              let hit = facetHits[i];

              this.hits.push({
                id: hit.value,
                highlightHtml: hit.highlighted,
                textLabel: hit.value,
                type: "category",
                faIcon: "theater-masks",
              });
            }
          });
      });
    },
  },
  mounted() {
    this.currentRefinement = this.query;
  },
  methods: {
    onSearchSubmit() {
      this.$router.push(this.createURL(this.currentRefinement));
    },
    createURL(
      query: string,
      facet: "event" | "venue" | "artist" | "category" = "event"
    ) {
      const vueRouter = this.$router;
      const currentSearchParams = JSON.parse(
        JSON.stringify(vueRouter.currentRoute.value.query)
      );

      switch (facet) {
        case "event": {
          currentSearchParams.q = query;
          break;
        }
        case "venue": {
          currentSearchParams.q = "";
          if (!currentSearchParams.venues)
            currentSearchParams.venues = [] as LocationQueryValue[];
          (currentSearchParams.venues as LocationQueryValue[]).push(query);
          break;
        }
        case "artist": {
          currentSearchParams.q = "";
          if (!currentSearchParams.artists)
            currentSearchParams.artists = [] as LocationQueryValue[];
          (currentSearchParams.artists as LocationQueryValue[]).push(query);
          break;
        }
        case "category": {
          currentSearchParams.q = "";
          if (!currentSearchParams.categories)
            currentSearchParams.categories = [] as LocationQueryValue[];
          (currentSearchParams.categories as LocationQueryValue[]).push(query);
          break;
        }
      }

      const rawRoute = qs.stringify(currentSearchParams);
      return "/search?" + rawRoute;
    },
  },
});

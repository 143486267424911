
import { defineComponent, PropType } from "vue";
import { mapState, mapGetters } from "vuex";
import { useHead } from "@vueuse/head";
import { HomepageQueryParams } from "@/router/types";
import Layout from "@/layouts/Layout.vue";
import {
  DeveloperOverrides,
  GeoInfo,
  LocationType,
  RecommendationsOptions,
} from "@/api/types";
import {
  PrimarySection,
  HeroSection,
  GetLocationSection,
  PromotedSection,
  Loading,
  LocationModal,
} from "@/components";
import GetBandsSection from "@/components/Section/GetBandsSection.vue";
import GetCategoriesSection from "@/components/Section/GetCategoriesSection.vue";
import GetGenresSection from "@/components/Section/GetGenresSection.vue";

export default defineComponent({
  name: "Home",
  components: {
    Layout,
    PrimarySection,
    HeroSection,
    GetLocationSection,
    GetBandsSection,
    GetCategoriesSection,
    GetGenresSection,
    PromotedSection,
    Loading,
    LocationModal,
  },
  props: {
    query: {
      type: Object as PropType<HomepageQueryParams>,
      required: false,
      default: () => ({} as HomepageQueryParams),
    },
  },
  data() {
    return {
      errorMessage: "",
      /* move into store */
      isBandsPreferencesSet: false,
      isCategoriesPreferencesSet: false,
      isGenrePreferencesSet: false,
    };
  },
  computed: {
    showEventNotFound(): boolean {
      const eventId = this.query.requestedEventId;
      if (typeof eventId === "string" && eventId.length > 0) return true;
      return false;
    },
    ...mapState("recommendations", ["sections", "status", "displayedLocation"]),
    ...mapState("session", [
      "lastSetLocationType",
      "lastSetGeo",
      "lastSetPostcode",
      "lastSetLocationVersion",
      "preferencesVersion",
      "bookmarkedEventKeys",
    ]),
    ...mapGetters("session", [
      "isLocationSet",
      "preferredBands",
      "preferredCategories",
      "preferredGenres",
      "bookmarkedEvents",
    ]),
    ...mapGetters("content", ["selectedPromotions"]),
  },
  watch: {
    lastSetLocationVersion() {
      this.fetchData();
    },
    preferencesVersion() {
      this.fetchData();
    },
  },
  mounted(): void {
    useHead({
      title: "Home — Oztix",
      meta: [
        {
          name: "description",
          content:
            "Oztix is the home of personalised experiences. Find the perfect event just for you.",
        },
      ],
    });
    if (this.query.postcode) {
      this.$store.dispatch("session/setPostcode", {
        postcode: this.query.postcode,
        interactionTarget: `url-override`,
      });

      let { postcode: _, ...leftoverParams } = this.query
      this.$router.replace({ path: '/', query: leftoverParams as any })
      
      this.$toast.success("Location added");
    } else {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      const options = {
        use: this.lastSetLocationType as LocationType,
        geo: this.lastSetGeo as GeoInfo,
        postcode: this.lastSetPostcode as string,
        customerId: this.query.r,
      } as RecommendationsOptions;

      const developerOverrides = this.query.trackingId
        ? ({ trackingId: this.query.trackingId } as DeveloperOverrides)
        : undefined;

      this.$store.dispatch("recommendations/fetchRecommendations", {
        options,
        developerOverrides,
      });

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      document.getElementById("app")?.focus();
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      document.getElementById("app")?.focus();
    },
    onSectionViewed(
      sectionType: string,
      sectionAnchorLink: string,
      index: number
    ): void {
      this.$store.dispatch("session/sectionViewed", {
        interactionTarget: `${sectionType}--${sectionAnchorLink}--index#${index}`,
      });
    },
    onLocationSet(
      location: unknown,
      element: string,
      index: number,
      type: "geo" | "postcode"
    ): void {
      // eslint-disable-next-line no-undef
      if (type == "geo" && location instanceof GeolocationPosition) {
        this.$store.dispatch("session/setGeolocation", {
          geolocation: location,
          interactionTarget: `${element}--index#${index}`,
        });
      }

      if (type == "postcode" && typeof location === "string") {
        this.$store.dispatch("session/setPostcode", {
          postcode: location,
          interactionTarget: `${element}--index#${index}`,
        });
      }

      this.$toast.success("Location added");
    },
    onLocationUnset(element: string, index: number) {
      this.$store.dispatch("session/unsetLocation", {
        interactionTarget: `${element}--index#${index}`,
      });
    },
    onSubmitPreferencesBands(
      bands: Array<{ key: number; name: string }>,
      element: string,
      index: number
    ) {
      this.$store.dispatch("session/submitPreferencesBands", {
        interactionTarget: `${element}--index#${index}`,
        bands,
      });
    },
    onSubmitPreferencesCategories(
      categories: Array<{ key: number; name: string }>,
      element: string,
      index: number
    ) {
      this.$store.dispatch("session/submitPreferencesCategories", {
        interactionTarget: `${element}--index#${index}`,
        categories,
      });
    },
    onSubmitPreferencesGenres(
      genres: Array<{ key: number; name: string }>,
      element: string,
      index: number
    ) {
      this.$store.dispatch("session/submitPreferencesGenres", {
        interactionTarget: `${element}--index#${index}`,
        genres,
      });
    },
    onResetPreferencesBands(element: string, index: number) {
      this.$store.dispatch("session/submitPreferencesBands", {
        interactionTarget: `${element}--index#${index}`,
        bands: [],
      });
    },
    onResetPreferencesCategories(element: string, index: number) {
      this.$store.dispatch("session/submitPreferencesCategories", {
        interactionTarget: `${element}--index#${index}`,
        categories: [],
      });
    },
    onResetPreferencesGenres(element: string, index: number) {
      this.$store.dispatch("session/submitPreferencesGenres", {
        interactionTarget: `${element}--index#${index}`,
        genres: [],
      });
    },
    onPromotedSectionClicked(
      sectionType: string,
      sectionAnchorLink: string,
      index: number
    ): void {
      this.$store.dispatch("session/promotionClicked", {
        interactionTarget: `${sectionType}--${sectionAnchorLink}--index#${index}`,
      });
    },
  },
});

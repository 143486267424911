import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "our-experience" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  class: "detailed-offering",
  id: "our-experience--detailed-offering",
  tabindex: "-1"
}
const _hoisted_4 = ["id"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  class: "detailed-offering",
  id: "our-marketing--detailed-offering",
  tabindex: "-1"
}
const _hoisted_8 = ["id"]
const _hoisted_9 = ["src"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  class: "detailed-offering",
  id: "our-platform--detailed-offering",
  tabindex: "-1"
}
const _hoisted_12 = ["id"]
const _hoisted_13 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b2b_hero = _resolveComponent("b2b-hero")!
  const _component_b2b_detailed_offering = _resolveComponent("b2b-detailed-offering")!
  const _component_b2b_integrations = _resolveComponent("b2b-integrations")!
  const _component_b2b_testimonials = _resolveComponent("b2b-testimonials")!
  const _component_b2b_partners = _resolveComponent("b2b-partners")!
  const _component_b2b_our_staff = _resolveComponent("b2b-our-staff")!
  const _component_b2b_contact_form = _resolveComponent("b2b-contact-form")!
  const _component_compact_layout = _resolveComponent("compact-layout")!

  return (_openBlock(), _createBlock(_component_compact_layout, { full: true }, {
    default: _withCtx(() => [
      _createVNode(_component_b2b_hero),
      _createVNode(_component_b2b_detailed_offering, {
        "background-image": require('@/assets/b2b/our-experience-bg.jpg'),
        "background-color": "var(--black)",
        "scroll-to": "#our-experience--detailed-offering"
      }, {
        main: _withCtx(({ toggleShowMore }) => [
          _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.content.ourExperience.heading), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.content.ourExperience.text), 1),
          _createElementVNode("button", {
            class: "primary-button",
            onClick: toggleShowMore
          }, " Find out more ", 8, _hoisted_2)
        ]),
        more: _withCtx(() => [
          _createElementVNode("section", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content.ourExperience.details, (detail) => {
              return (_openBlock(), _createElementBlock("div", {
                key: detail.id
              }, [
                _createElementVNode("h3", {
                  id: detail.id
                }, [
                  _createElementVNode("img", {
                    class: "detailed-offering--icon",
                    alt: "",
                    src: require(`@/assets/b2b/${detail.icon}`)
                  }, null, 8, _hoisted_5),
                  _createTextVNode(_toDisplayString(detail.heading), 1)
                ], 8, _hoisted_4),
                _createElementVNode("p", null, _toDisplayString(detail.text), 1)
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["background-image"]),
      _createVNode(_component_b2b_detailed_offering, {
        "background-image": require('@/assets/b2b/our-marketing-bg.jpg'),
        "background-color": "var(--black)",
        "text-align": "right",
        "scroll-to": "#our-marketing--detailed-offering"
      }, {
        main: _withCtx(({ toggleShowMore }) => [
          _createElementVNode("h2", null, _toDisplayString(_ctx.content.ourMarketing.heading), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.content.ourMarketing.text), 1),
          _createElementVNode("button", {
            class: "primary-button",
            onClick: toggleShowMore
          }, " Find out more ", 8, _hoisted_6)
        ]),
        more: _withCtx(() => [
          _createElementVNode("section", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content.ourMarketing.details, (detail) => {
              return (_openBlock(), _createElementBlock("div", {
                key: detail.id
              }, [
                _createElementVNode("h3", {
                  id: detail.id
                }, [
                  _createElementVNode("img", {
                    class: "detailed-offering--icon",
                    alt: "",
                    src: require(`@/assets/b2b/${detail.icon}`)
                  }, null, 8, _hoisted_9),
                  _createTextVNode(_toDisplayString(detail.heading), 1)
                ], 8, _hoisted_8),
                _createElementVNode("p", null, _toDisplayString(detail.text), 1)
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["background-image"]),
      _createVNode(_component_b2b_detailed_offering, {
        "background-image": require('@/assets/b2b/our-platform-bg.png'),
        "background-color": "var(--black)",
        "scroll-to": "#our-platform--detailed-offering"
      }, {
        main: _withCtx(({ toggleShowMore }) => [
          _createElementVNode("h2", null, _toDisplayString(_ctx.content.ourPlatform.heading), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.content.ourPlatform.text), 1),
          _createElementVNode("button", {
            class: "primary-button",
            onClick: toggleShowMore
          }, " Find out more ", 8, _hoisted_10)
        ]),
        more: _withCtx(() => [
          _createElementVNode("section", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content.ourPlatform.details, (detail) => {
              return (_openBlock(), _createElementBlock("div", {
                key: detail.id
              }, [
                _createElementVNode("h3", {
                  id: detail.id
                }, [
                  _createElementVNode("img", {
                    class: "detailed-offering--icon",
                    alt: "",
                    src: require(`@/assets/b2b/${detail.icon}`)
                  }, null, 8, _hoisted_13),
                  _createTextVNode(_toDisplayString(detail.heading), 1)
                ], 8, _hoisted_12),
                _createElementVNode("p", null, _toDisplayString(detail.text), 1)
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["background-image"]),
      _createVNode(_component_b2b_integrations),
      _createVNode(_component_b2b_testimonials),
      _createVNode(_component_b2b_partners),
      _createVNode(_component_b2b_our_staff),
      _createVNode(_component_b2b_contact_form)
    ]),
    _: 1
  }))
}

import { defineComponent, PropType } from "vue";
import { CloseButton } from "@/components";

type BadgeItem = {
  name: string;
  key: number;
};

export default defineComponent({
  name: "BadgeList",
  components: {
    CloseButton,
  },
  emits: ["removeItem"],
  props: {
    items: {
      type: Object as PropType<BadgeItem[]>,
      required: true,
    },
  },
});

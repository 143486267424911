import prodConfig from './config.production'
import localConfig from './config.local'
import { PersonalisationConfig } from './types';

let config;

if (process.env.NODE_ENV === 'production') {
    config = prodConfig
} else {
    config = localConfig
}

export default <PersonalisationConfig>config

import { defineComponent } from "vue";
import NavMenu from "@/components/Menu/NavMenu.vue";
import TheFooter from "@/components/Footer/TheFooter.vue";
import OztixLogo from "@/components/Logo/OztixLogo.vue";

export default defineComponent({
  name: "CompactLayout",
  components: {
    OztixLogo,
    NavMenu,
    TheFooter,
  },
});


import { defineComponent } from "vue";
import { v4 as uuidv4 } from "uuid";
import BaseModal from "@/components/Modal/BaseModal.vue";

export default defineComponent({
  components: { BaseModal },
  name: "SimpleModal",
  props: {
    buttonLabel: {
      type: String,
      required: true,
      default: "Open",
    },
    modalHeading: {
      type: String,
      required: true,
      default: "Heading",
    },
    message: {
      type: String,
      required: true,
      default: "Message",
    },
  },
  emits: ["closeModal"],
});

export type EventStatus = "cancelled" | "postponed" | "sold out" | "rescheduled"

export const isEventStatus = (value: unknown): value is EventStatus => {
    if (typeof (value) !== "string") return false
    if (["cancelled", "postponed", "sold out", "rescheduled"].includes(value)) return true
    return false
}

export type OztixEvent = {
    eventKey: number;
    name: string;
    url: string;
    when?: string;
    where?: string;
    image?: string; /* variable size image */
    studioImage?: string; /* 2-by-1 image */
    status?: EventStatus;
    bookmarked: boolean;
}

export type BandKey = number;
export type CategoryKey = number;
export type GenreKey = number;
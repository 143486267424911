import { BandKey, CategoryKey, GenreKey } from "@/domain/types"
import { AxiosResponse } from "axios"

export type GeoInfo = {
    latitude: number;
    longitude: number;
}

export type RecommendationsOptions = {
    geo?: GeoInfo;
    postcode?: string;
    use: LocationType;
    customerId?: string;
}

interface InteractionFactRequestData {
    sessionId: null | string;
    customerId: null | string;  // TODO currently null, not saved to store
    interactionTarget: string;
}

interface EventInteractionFactRequestData extends InteractionFactRequestData {
    eventKey: number;
}

interface LocationInteractionFactRequestData extends InteractionFactRequestData {
    locationType: LocationType,
    geo?: GeoInfo;
    postcode?: string;
}

interface PreferencesBandsInteractionFactRequestData extends InteractionFactRequestData {
    bands: Array<BandKey>;
}

interface PreferencesCategoriesInteractionFactRequestData extends InteractionFactRequestData {
    categories: Array<CategoryKey>;
}

interface PreferencesGenresInteractionFactRequestData extends InteractionFactRequestData {
    genres: Array<GenreKey>;
}

export type EventViewedRequestData = EventInteractionFactRequestData
export type EventClickedRequestData = EventInteractionFactRequestData
export type EventBookmarkedRequestData = EventInteractionFactRequestData
export type EventUnbookmarkedRequestData = EventInteractionFactRequestData
export type SectionShowMoreClickedRequestData = InteractionFactRequestData
export type SectionViewedRequestData = InteractionFactRequestData
export type LocationUpdatedRequestData = LocationInteractionFactRequestData
export type PreferredBandsRequestData = PreferencesBandsInteractionFactRequestData
export type PreferredCategoriesRequestData = PreferencesCategoriesInteractionFactRequestData
export type PreferredGenresRequestData = PreferencesGenresInteractionFactRequestData

export interface PersonalisationApi {
    developer: {
        getUserProfile: (trackingId?: string, customerId?: string) => Promise<AxiosResponse<UserProfileResponse>>;
    },
    interactionFact: {
        postEventViewed: (data: EventViewedRequestData) => Promise<AxiosResponse<void>>;
        postEventClicked: (data: EventClickedRequestData) => Promise<AxiosResponse<void>>;
        postSectionViewed: (data: SectionViewedRequestData) => Promise<AxiosResponse<void>>;
        postSectionShowMoreClicked: (data: SectionShowMoreClickedRequestData) => Promise<AxiosResponse<void>>;
    },
    recommendation: {
        postRecommendations: (options: RecommendationsOptions, overrides?: DeveloperOverrides) => Promise<AxiosResponse<RecommendationsResponse>>;
    },
    session: {
        getSessionData: () => Promise<AxiosResponse<SessionDataResponse>>;
        postEventBookmarked: (data: EventBookmarkedRequestData) => Promise<AxiosResponse<void>>;
        deleteEventBookmarked: (data: EventUnbookmarkedRequestData) => Promise<AxiosResponse<void>>;
        postLocationUpdated: (data: LocationUpdatedRequestData) => Promise<AxiosResponse<void>>;
        postPreferredBands: (data: PreferredBandsRequestData) => Promise<AxiosResponse<void>>;
        postPreferredCategories: (data: PreferredCategoriesRequestData) => Promise<AxiosResponse<void>>;
        postPreferredGenres: (data: PreferredGenresRequestData) => Promise<AxiosResponse<void>>;
    }
    seeka: {
        setupRequest: (data: SeekaBrandSetupRequest) => Promise<AxiosResponse<AsanaTaskResponse>>;
    },
}

export type SectionEvent = {
    eventKey: number;
    score: number;
}

export type RecommendSection = {
    title: string;
    id: string;
    events: Array<SectionEvent>;
}

export type Venue = {
    name: string;
    locality: string;
    state: string;
    timezone: string;
}

export type ViewEvent = {
    eventKey: number;
    eventGuid: string;
    eventName: string;
    eventImage1: string; // deprecating
    homepageImage: string;
    dateStart: string;
    eventUrl: string;
    categories: Array<string>;
    venue: Venue;
    isCancelled?: boolean;
    isPostponed?: boolean;
    isRescheduled?: boolean;
    availability?: boolean;
    availabilityDate?: string;
}

export type RecommendationsResponse = {
    sections: Array<RecommendSection>,
    catalog: Array<ViewEvent>,
    options: RecommendationsEngineOptions,
    displayedLocation: string,
    trackingId?: string,
    customerId?: string,
}

export type RecommendationsEngineOptions = {
    geo: GeoInfo,
    postcode: string,
    use: LocationType
}

export enum LocationType { // must match src/TicketSolutions.Personalisation/Domain/RecommendationsEngine/Model/LocationType.cs
    None,
    Postcode,
    Geo
}

export type DeveloperOverrides = {
    trackingId: string,
}

export type UserProfileResponse = {
    userProfile: UserProfile;
}

export type UserProfile = {
    trackingId: string,
    customerGuid: string,
    purchasedEventKeys: Array<number>,
    waitlistedEventKeys: Array<number>,
    preregisteredEventKeys: Array<number>,
    pageViewEventKeys: Array<number>,
    profileCategoryKeys: Array<number>,
    profileBandKeys: Array<number>,
    spotifyBandKeys: Array<number>,
    spotifyGenreKeys: Array<number>,
    lastSetPostcode: string,
    lastSetPostcodeQuarterHourKey: number,
    recommendedEvents: Array<{ eventKey: number; score: number; }>
}

export type SessionDataResponse = {
    bookmarkedEventKeys: Array<number>,
    lastSetLocationType: LocationType,
    lastSetGeo?: GeoInfo;
    lastSetPostcode?: string;
    preferences: {
        bands: Array<BandKey>;
        categories: Array<CategoryKey>;
        genres: Array<GenreKey>;
    }
}

export type SeekaBrandSetupRequest = {
    eventVenueName: string;
    entityName: string;
    email: string;
    additionalBrandUsers: string;
}


export type AsanaTask = {
    name: string;
    resourceSubtype: string;
    approvalStatus: string;
    assigneeStatus: string;
    completed: boolean;
    dueOn: string;
    htmlNotes: string;
    assignee: string;
    projects: string[];
}

export type AsanaTaskResponse = {
    data: any;
}


import { defineComponent, PropType } from "vue";
import { v4 as uuidv4 } from "uuid";
import algoliasearch from "algoliasearch/lite";
import { SearchIndex } from "@algolia/client-search/dist/client-search";
import config from "@/config";
import BaseModal from "./BaseModal.vue";
import { IconCategory, IconMusic, IconBand, CloseButton } from "@/components";
import BadgeList from "@/components/List/BadgeList.vue";
import AutocompleteInput from "@/components/Form/AutocompleteInput.vue";

type DataCaptureType = "category" | "genre" | "band";

export default defineComponent({
  name: "DataCaptureModal",
  components: {
    IconCategory,
    IconMusic,
    IconBand,
    BaseModal,
    AutocompleteInput,
    CloseButton,
    BadgeList,
  },
  emits: ["submitPreferences", "resetPreferences"],
  props: {
    buttonLabel: {
      type: String,
      required: true,
    },
    modalHeading: {
      type: String,
      required: true,
    },
    dataType: {
      type: String as PropType<DataCaptureType>,
      required: true,
    },
  },
  data() {
    return {
      autocompleteInputId: "autocomplete-input--" + uuidv4(),
      selected: [] as Array<{ key: number; name: string }>,
      errorMessage: "",
      selectedListStatus: "",
      searchClient: algoliasearch(
        config.ALGOLIA_APPLICATION_ID,
        config.ALGOLIA_REFERENCE_API_KEY
      ),
      searchIndex: null as null | SearchIndex,
      options: [] as Array<{ key: number; name: string }>,
    };
  },
  computed: {
    canSubmit(): boolean {
      return this.selected.length > 0;
    },
    icon(): string {
      if (this.dataType === "genre") return "icon-music";
      if (this.dataType === "category") return "icon-category";
      if (this.dataType === "band") return "icon-band";
      return "";
    },
    label(): string {
      if (this.dataType === "genre") return "Preferred genres";
      if (this.dataType === "category") return "Preferred categories";
      if (this.dataType === "band") return "Preferred artists";
      return "";
    },
    algoliaIndex(): string {
      if (this.dataType === "genre") return "prod_oztix_genres";
      if (this.dataType === "category") return "prod_oztix_event_categories";
      if (this.dataType === "band") return "prod_oztix_artists";
      return "";
    },
  },
  watch: {
    selected: {
      deep: true,
      handler() {
        this.errorMessage = "";
        if (this.selected.length === 0) {
          this.selectedListStatus = "";
          setTimeout(() => {
            this.selectedListStatus = `Preferred ${this.dataType} list empty.`;
          }, 500);
        } else {
          this.selectedListStatus = "";
          setTimeout(() => {
            this.selectedListStatus = `Preferred ${this.dataType} list has ${this.selected.length} items. Submit to see events based on your preferences.`;
          }, 500);
        }
      },
    },
  },
  mounted() {
    this.initIndex();
  },
  methods: {
    initIndex() {
      this.searchIndex = this.searchClient.initIndex(this.algoliaIndex);
    },
    suggest(
      query: string,
      populateResults: (filteredResults: Array<string>) => void
    ) {
      if (this.searchIndex) {
        (this.searchIndex as any)
          .search(query, {
            hitsPerPage: 12,
          })
          .then(({ hits }: { hits: { key: number; name: string }[] }) => {
            populateResults(hits.map((h) => h.name));
            if (hits.length > 0) this.options = hits;
          });
      }
    },
    onActionButtonClicked(value: { key: number; name: string }) {
      if (!value.key || !value.name) {
        // TODO: add helper message
        return;
      }

      const findDuplicates = [...this.selected].filter(
        (s) => s.key === value.key
      );
      const isNotAlreadyListed = findDuplicates.length == 0;

      if (isNotAlreadyListed) {
        this.selected.push(value);
      }
    },
    removeSelectedItem(value: { key: number; name: string }) {
      const index = this.selected.findIndex((s) => s.key === value.key);
      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        console.warn(`Could not remove ${this.dataType}`, value);
      }

      this.$nextTick(() => {
        const input = document.getElementById(this.autocompleteInputId);
        if (input && input instanceof HTMLInputElement) {
          input.focus();
        }
      });
    },
    submit() {
      if (this.canSubmit) {
        this.$emit("submitPreferences", this.selected);
        this.$toast.success("Preferences submitted.");
      } else {
        this.errorMessage = `Please add at least one ${this.dataType} first.`;
      }
    },
    resetPreferences() {
      this.$emit("resetPreferences");
    },
  },
});


import { defineComponent } from "vue";

export default defineComponent({
  name: "Stack",
  props: {
    columnCount: {
      type: Number,
      required: true,
      default: 2,
    },
    gutterWidth: {
      type: Number,
      required: true,
      default: 10,
    },
    gutterHeight: {
      type: Number,
      required: true,
      default: 10,
    },
    itemCount: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      stackItems: null as null | HTMLCollection,
      containerWidth: 0,
      columnRef: [],
    };
  },
  mounted() {
    window.addEventListener("resize", this.reflow);
    const containerEl = this.$refs.container as HTMLElement;
    this.stackItems = containerEl.children;
    this.update();
  },
  unmounted() {
    window.removeEventListener("resize", this.reflow);
  },
  watch: {
    itemCount() {
      this.update();
    },
  },
  computed: {
    columnWidth(): number {
      return (
        (this.containerWidth - (this.columnCount - 1) * this.gutterWidth) /
        this.columnCount
      );
    },
  },
  methods: {
    getColumnRef(): Array<{ x: number; h: number }> {
      let cols = [];
      for (let i = 0; i < this.columnCount; i++)
        cols.push({
          x: i * (this.columnWidth + this.gutterWidth),
          h: 0,
        });
      return cols;
    },
    update() {
      this.$nextTick(this.reflow);
    },
    onImagesLoaded() {
      this.$nextTick(this.reflow);
    },
    updateDimensions() {
      this.containerWidth = this.getContainerWidth(); // sets column count and column width
    },
    getContainerWidth(): number {
      return this.$refs.container
        ? (this.$refs.container as HTMLElement).clientWidth
        : 0;
    },
    reflow(): void {
      const newWidth = this.getContainerWidth();

      this.containerWidth = newWidth;
      this.updateDimensions();

      this.$emit("reflow", {
        containerWidth: this.containerWidth,
        columnCount: this.columnCount,
        columnWidth: this.columnWidth,
      });

      this.$nextTick(() => {
        if (this.stackItems) {
          const colRef = this.getColumnRef();
          for (let i = 0; i < this.stackItems.length; i++) {
            const el = this.stackItems.item(i) as HTMLElement;
            el.style.width = this.columnWidth + "px";

            let n = 0;
            if (i < this.columnCount) {
              n = i;
            } else {
              let minH = -1;
              colRef.forEach((col, j) => {
                if (minH == -1 || col.h < minH) {
                  n = j;
                  minH = col.h;
                }
              });
            }

            el.style.transform =
              "translate(" + colRef[n].x + "px, " + colRef[n].h + "px)";
            colRef[n].h += el.offsetHeight + this.gutterHeight;
          }

          let containerHeight = 0;
          const containerEl = this.$refs.container as HTMLElement;
          colRef.forEach(
            (col) => (containerHeight = Math.max(containerHeight, col.h))
          );
          if (containerEl) {
            // TODO: containerEl shouldn't be null - check later why?
            containerEl.style.height = containerHeight + "px";
          }
        }
      });
    },
  },
});

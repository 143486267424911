
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    const integrations = [
      "Stripe",
      "MerchantWarrior",
      "GooglePay",
      "PayPal",
      "ZipPay",
      "GoogleAnalytics",
      "Meta",
      "TikTok",
      "Algolia",
      "Tixel",
      "Twitter",
      "Spotify",
      "GoogleWallet",
      "Songkick",
      "Bandsintown",
      "TheMusic",
      "Pinterest",
      "FestivalCurrency",
      "Tappit",
      "GoogleAds",
      "ROKT",
      "BookingProtect"
    ];
    return { integrations };
  },
});


import { defineComponent, PropType } from "vue";
import { v4 as uuidv4 } from "uuid";
import { AlgoliaEvent } from "./types";

export default defineComponent({
  name: "SearchBox",
  props: {
    label: {
      type: String,
      required: false,
      default: "Search",
    },
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    hits: {
      type: Array as PropType<Array<AlgoliaEvent>>,
      required: false,
      default: () => [],
    },
    showSearchFor: {
      // show extra menu item to just search for text
      type: Boolean,
      required: false,
      default: () => true,
    },
    theme: {
      type: String as PropType<"white" | "blue">,
      required: false,
      default: "white",
    },
  },
  emits: ["update:modelValue", "submit"],
  data() {
    const componentGuid = uuidv4();
    return {
      componentGuid,
      expanded: false,
      currentSuggestion: null as null | number,
    };
  },
  computed: {
    suggestionListLength(): number {
      if (this.showSearchFor) return this.hits.length + 1; // (for extra last "search for x" option)
      return this.hits.length;
    },
  },
  watch: {
    $route(): void {
      /* on route change, if the search suggestions are open, need to close it */
      this.expanded = false;
    },
    currentSuggestion(newVal: null | number) {
      if (newVal === null) {
        this.$nextTick(() => {
          const search = document.getElementById(
            `${this.componentGuid}--search`
          );
          search && search.focus();
        });
        return;
      }

      if (newVal >= this.suggestionListLength) return;

      const id = `${this.componentGuid}--suggestions--${newVal}`;

      this.$nextTick(() => {
        const suggestion = document.getElementById(id);
        suggestion && suggestion.focus();
      });
    },
  },
  methods: {
    onSubmit(event: any) {
      this.$emit("submit");
    },
    handleInput(ev: Event) {
      if (ev.target instanceof HTMLInputElement) {
        this.$emit("update:modelValue", ev.target.value);
      }

      if (this.modelValue && this.suggestionListLength > 0) {
        this.expanded = true;
      } else {
        this.expanded = false;
      }
    },
    handleInputKeydown(event: KeyboardEvent) {
      if (!this.expanded) {
        return;
      }

      if (event.ctrlKey || event.shiftKey) {
        return;
      }

      switch (event.key) {
        case "Tab": {
          event.preventDefault();
          this.currentSuggestion = 0;
          break;
        }
        case "ArrowDown": {
          event.preventDefault();
          this.currentSuggestion = 0;
          break;
        }
        case "ArrowUp": {
          event.preventDefault();
          this.currentSuggestion = this.suggestionListLength - 1;
          break;
        }
      }
    },
    handleInputKeyup(event: KeyboardEvent) {
      if (event.ctrlKey || event.shiftKey) {
        return;
      }

      switch (event.key) {
        case "Enter": {
          // eslint-disable-next-line
          event.preventDefault();
          this.$emit("submit", (event.target as any).value);
          this.expanded = false;
          this.currentSuggestion = null;
          break;
        }
        case "Escape": {
          this.expanded = false;
          this.currentSuggestion = null;
          break;
        }
      }
    },
    handleSuggestionListKeydown(event: KeyboardEvent) {
      if (event.ctrlKey || event.shiftKey) {
        return;
      }

      switch (event.key) {
        case "Tab": {
          event.preventDefault();
          if (this.currentSuggestion === null) return;
          if (this.currentSuggestion >= this.suggestionListLength - 1) {
            this.currentSuggestion = null; // go back to search
            return;
          } else {
            this.currentSuggestion++;
          }
          break;
        }
        case "ArrowDown": {
          event.preventDefault();
          if (this.currentSuggestion === null) return;
          if (this.currentSuggestion >= this.suggestionListLength - 1) {
            this.currentSuggestion = null; // go back to search
            return;
          } else {
            this.currentSuggestion++;
          }
          break;
        }
        case "ArrowUp": {
          event.preventDefault();
          if (this.currentSuggestion === null) return;
          if (this.currentSuggestion <= 0) {
            this.currentSuggestion = null; // go back to search
            return;
          } else {
            this.currentSuggestion--;
          }
          break;
        }
      }
    },
    handleSuggestionListKeyup(event: KeyboardEvent) {
      if (event.ctrlKey || event.shiftKey) {
        return;
      }

      switch (event.key) {
        case "Escape": {
          this.expanded = false;
          this.currentSuggestion = null;
          break;
        }
        case "Enter": {
          const parent = event.target as HTMLLIElement;
          if (
            parent &&
            parent.firstElementChild &&
            (parent.firstElementChild instanceof HTMLAnchorElement ||
              parent.firstElementChild instanceof HTMLButtonElement)
          ) {
            parent.firstElementChild.click();
          }
          this.expanded = false;
          this.currentSuggestion = null;
          break;
        }
      }
    },
    handleClickOutside() {
      this.expanded = false;
    },
  },
});

export type ClientTestimonial = {
    client: string;
    image: string;
    imageDescription: string;
    quote: string;
    testimonial: string;
}

export const testimonials = {
    value: [
        {
            "client": "The Croxton",
            "image": "client-croxton.jpg",
            "imageDescription": "",
            "quote": "We have relied on their custom technology and vast audience database to build our business",
            "testimonial": "Oztix have been a key partner in establishing and developing The Croxton Bandroom as one of Australia’s best-loved live music venues."
        },
        {
            "client": "New World Artists\nEdwin Tehrani, Director",
            "image": "client-nwa.jpg",
            "imageDescription": "",
            "quote": "Lightning speed delivery, attention to detail, client engagement and service levels",
            "testimonial": "Oztix understand the demanding environment in which we all operate because they are from live music.  Smash and Stu’s working history predates Oztix and they built a company and a team who are passionate and knowledgeable about live music."
        },
        {
            "client": "Boating Industry Association\nDomenic Genua, General Manager.",
            "image": "client-bia.jpg",
            "imageDescription": "",
            "quote": "Business can be complicated and challenging, and we know that with Oztix we have a caring team of people",
            "testimonial": "When the boating Industry Association started working with Oztix we made it clear that we wanted a service provider to join our family and be part of our events success."
        },
        {
            "client": "Vibestown Festival",
            "image": "client-vibestown.jpg",
            "imageDescription": "",
            "quote": "The scalability of their system and customer service has followed us all the way through",
            "testimonial": "As we embark on new festivals and shows, we find the customisation and integration of their system so important and we incorporate it into our operations, marketing and reporting departments."
        },
        {
            "client": "World Time Attack Challenge\nIan Baker, Chief Executive Officer",
            "image": "client-time-attack.jpg",
            "imageDescription": "",
            "quote": "The team is extremely experienced and are able to eliminate many problems before they even happened",
            "testimonial": "With over ten years in the international events promotion business, we have used many ticket suppliers and Oztix was without question the best we have ever partnered with."
        }
    ] as ClientTestimonial[]
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ea7d980c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-haspopup", "aria-controls", "aria-expanded"]
const _hoisted_2 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_menu = _resolveComponent("icon-menu")!

  return (_openBlock(), _createElementBlock("button", {
    ref: "btn",
    "aria-haspopup": _ctx.ariaHaspopup,
    "aria-controls": _ctx.ariaControls,
    "aria-expanded": _ctx.ariaExpanded
  }, [
    _createVNode(_component_icon_menu, {
      "has-focus": _ctx.hasFocus,
      "has-hover": _ctx.hasHover
    }, null, 8, ["has-focus", "has-hover"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1)
  ], 8, _hoisted_1))
}
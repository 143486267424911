
import { defineComponent, ref } from "vue";
import CompactLayout from "@/layouts/CompactLayout.vue";
import { api } from "@/api";
import { SeekaBrandSetupRequest, AsanaTaskResponse } from "@/api/types";



export default defineComponent({
    components: {
        CompactLayout
    },
    setup() {
        const SeekaBrandSetupRequest = ref<SeekaBrandSetupRequest>({
            eventVenueName: "",
            entityName: "",
            email: "",
            additionalBrandUsers: "",
        });

        const additionalBrandUsers = ref<{ name: string; email: string }[]>([{ name: "", email: "" }]);


        const addAdditionalBrandUser = () => additionalBrandUsers.value.push({ name: "", email: "" });


        const removeAdditionalBrandUser = (index: number) => {
            if (additionalBrandUsers.value.length > 1) {
                additionalBrandUsers.value.splice(index, 1);
            }
        };

        const isSubmitting = ref(false);
        const responseMessage = ref<string | null>(null);
        const responseClass = ref<string>("");

        const setResponse = (message: string, isSuccess: boolean) => {
            responseMessage.value = message;
            responseClass.value = isSuccess ? "text-brand-500" : "text-red-600";
        };

        const getSubmitButtonText = () => isSubmitting.value ? "Submitting..." : "Submit Request";

        const submitSeekaBrandSetupRequest = async () => {
            isSubmitting.value = true;
            responseMessage.value = null;

            SeekaBrandSetupRequest.value.additionalBrandUsers = additionalBrandUsers.value
                .map(entry => `${entry.name}\n${entry.email}\n`)
                .join("\n");

            const requestData = {
                eventVenueName: SeekaBrandSetupRequest.value.eventVenueName,
                entityName: SeekaBrandSetupRequest.value.entityName,
                email: SeekaBrandSetupRequest.value.email,
                additionalBrandUsers: SeekaBrandSetupRequest.value.additionalBrandUsers,
            };

            try {
                const result = await api.seeka.setupRequest(requestData);

                SeekaBrandSetupRequest.value = {
                    eventVenueName: "",
                    entityName: "",
                    email: "",
                    additionalBrandUsers: "",
                };
                additionalBrandUsers.value = [{ name: "", email: "" }];

                setResponse("Thank you. Your request has been submitted.", true);
            } catch (error) {

                setResponse("Failed to submit the request. Please try again.", false);
            } finally {
                isSubmitting.value = false;
            }
        };

        const isRemoveButtonVisible = () => additionalBrandUsers.value.length > 1;


        return {
            SeekaBrandSetupRequest,
            additionalBrandUsers,
            addAdditionalBrandUser,
            removeAdditionalBrandUser,
            submitSeekaBrandSetupRequest,
            isSubmitting,
            responseMessage,
            responseClass,
            getSubmitButtonText,
            isRemoveButtonVisible,
        };
    },
});

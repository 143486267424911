import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-350c0a86"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  width: "28",
  height: "26",
  viewBox: "0 0 28 26",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      class: _normalizeClass(_ctx.className),
      d: "M24.4677 7.0986C24.1264 6.29655 23.6342 5.56975 23.0187 4.95886C22.4028 4.34616 21.6766 3.85925 20.8796 3.52462C20.0532 3.17624 19.1668 2.99793 18.2719 3.00002C17.0164 3.00002 15.7915 3.34889 14.727 4.00787C14.4724 4.1655 14.2305 4.33865 14.0013 4.5273C13.7721 4.33865 13.5301 4.1655 13.2755 4.00787C12.211 3.34889 10.9861 3.00002 9.73063 3.00002C8.82659 3.00002 7.95057 3.17575 7.12293 3.52462C6.3233 3.86057 5.60261 4.34382 4.98379 4.95886C4.36751 5.56906 3.87521 6.29604 3.53478 7.0986C3.18081 7.93331 3 8.8197 3 9.73193C3 10.5925 3.17317 11.4892 3.51696 12.4014C3.80472 13.1638 4.21727 13.9546 4.74441 14.7531C5.57969 16.0168 6.7282 17.3347 8.15429 18.6708C10.5175 20.8855 12.8578 22.4153 12.9572 22.4773L13.5607 22.8701C13.8281 23.0433 14.1719 23.0433 14.4393 22.8701L15.0428 22.4773C15.1421 22.4127 17.4799 20.8855 19.8457 18.6708C21.2718 17.3347 22.4203 16.0168 23.2556 14.7531C23.7827 13.9546 24.1978 13.1638 24.483 12.4014C24.8268 11.4892 25 10.5925 25 9.73193C25.0025 8.8197 24.8217 7.93331 24.4677 7.0986Z"
    }, null, 2),
    _createElementVNode("path", {
      d: "M24.4677 7.0986C24.1264 6.29655 23.6342 5.56975 23.0187 4.95886C22.4028 4.34616 21.6766 3.85925 20.8796 3.52462C20.0532 3.17624 19.1668 2.99793 18.2719 3.00002C17.0164 3.00002 15.7915 3.34889 14.727 4.00787C14.4724 4.1655 14.2305 4.33865 14.0013 4.5273C13.7721 4.33865 13.5301 4.1655 13.2755 4.00787C12.211 3.34889 10.9861 3.00002 9.73063 3.00002C8.82659 3.00002 7.95057 3.17575 7.12293 3.52462C6.3233 3.86057 5.60261 4.34382 4.98379 4.95886C4.36751 5.56906 3.87521 6.29604 3.53478 7.0986C3.18081 7.93331 3 8.8197 3 9.73193C3 10.5925 3.17317 11.4892 3.51696 12.4014C3.80472 13.1638 4.21727 13.9546 4.74441 14.7531C5.57969 16.0168 6.7282 17.3347 8.15429 18.6708C10.5175 20.8855 12.8578 22.4153 12.9572 22.4773L13.5607 22.8701C13.8281 23.0433 14.1719 23.0433 14.4393 22.8701L15.0428 22.4773C15.1421 22.4127 17.4799 20.8855 19.8457 18.6708C21.2718 17.3347 22.4203 16.0168 23.2556 14.7531C23.7827 13.9546 24.1978 13.1638 24.483 12.4014C24.8268 11.4892 25 10.5925 25 9.73193C25.0025 8.8197 24.8217 7.93331 24.4677 7.0986Z",
      class: _normalizeClass(_ctx.outlineClass),
      "stroke-width": "3"
    }, null, 2)
  ]))
}
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "detailed-offering--more--content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "ticket-your-event--section ticket-your-event--section--detailed-offering",
    style: _normalizeStyle({
      'background-color': _ctx.backgroundColor,
    })
  }, [
    _createElementVNode("section", null, [
      _createElementVNode("div", {
        class: _normalizeClass(["detailed-offering--main", _ctx.textAlign]),
        id: "detailed-offereing--main",
        style: _normalizeStyle({
          'background-image': 'url(' + _ctx.backgroundImage + ')',
          'text-align': _ctx.textAlign,
          'background-position-x': _ctx.textAlign === 'right' ? 'left' : 'right',
        })
      }, [
        _renderSlot(_ctx.$slots, "main", { toggleShowMore: _ctx.toggleShowMore })
      ], 6),
      _createElementVNode("div", {
        class: _normalizeClass(["detailed-offering--more", { open: _ctx.showMore }]),
        ref: "more"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "more")
        ])
      ], 2)
    ])
  ], 4))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86fd5408"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-disabled", "tabindex"]
const _hoisted_2 = {
  class: "dialog-backdrop",
  ref: "backdrop"
}
const _hoisted_3 = ["aria-modal", "aria-hidden"]
const _hoisted_4 = {
  class: "modal__container",
  tabindex: "-1"
}
const _hoisted_5 = { class: "modal__header" }
const _hoisted_6 = { id: "modal__title" }
const _hoisted_7 = { class: "modal__contents" }
const _hoisted_8 = { class: "modal__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_button = _resolveComponent("close-button")!
  const _component_focus_trap = _resolveComponent("focus-trap")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "primary-button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args))),
      ref: "triggerButton",
      "aria-disabled": _ctx.isOpen,
      tabindex: _ctx.isOpen ? -1 : 0
    }, _toDisplayString(_ctx.buttonLabel), 9, _hoisted_1),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      _createElementVNode("div", _hoisted_2, null, 512),
      _createVNode(_component_focus_trap, {
        ref: "focusTrap",
        active: _ctx.isOpen,
        "onUpdate:active": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isOpen) = $event)),
        "initial-focus": _ctx.initialFocusId,
        "fallback-focus": () => _ctx.$refs.triggerButton,
        "escape-deactivates": true,
        "allow-outside-click": false,
        onDeactivate: _ctx.closeModal
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            role: "dialog",
            "aria-labelledby": "modal__title",
            class: _normalizeClass(_ctx.isOpen ? '' : 'hidden'),
            "aria-modal": _ctx.isOpen ? true : false,
            "aria-hidden": _ctx.isOpen ? false : true
          }, [
            _createElementVNode("section", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "icon", {}, undefined, true),
                _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.modalHeading), 1),
                _renderSlot(_ctx.$slots, "description", {}, undefined, true)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _renderSlot(_ctx.$slots, "contents", {}, undefined, true)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
              ])
            ]),
            _createVNode(_component_close_button, {
              class: "close-button",
              onClick: _ctx.closeModal,
              ref: "closeButton"
            }, null, 8, ["onClick"])
          ], 10, _hoisted_3)
        ]),
        _: 3
      }, 8, ["active", "initial-focus", "fallback-focus", "onDeactivate"])
    ]))
  ], 64))
}
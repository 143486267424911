import axios, { AxiosResponse } from 'axios';
import config from '@/config'
import {
    PersonalisationApi,
    DeveloperOverrides,
    RecommendationsOptions,
    RecommendationsResponse,
    SessionDataResponse,
    EventBookmarkedRequestData,
    UserProfileResponse,
    EventUnbookmarkedRequestData,
    EventClickedRequestData,
    SectionShowMoreClickedRequestData,
    EventViewedRequestData,
    SectionViewedRequestData,
    LocationUpdatedRequestData,
    PreferredCategoriesRequestData,
    PreferredBandsRequestData,
    PreferredGenresRequestData,
    AsanaTaskResponse,
    SeekaBrandSetupRequest
} from './types';

const ax = axios.create({
    baseURL: '//' + config.API_BASE_URI,
    withCredentials: true, // send cookies 🍪
})

export const api: PersonalisationApi = {
    developer: {
        getUserProfile(trackingId?: string, customerId?: string): Promise<AxiosResponse<UserProfileResponse>> {
            let path = '/api/developer/user-profile?';

            if (trackingId) {
                path += `&trackingId=${trackingId}`;
            }

            if (customerId) {
                path += `customerId=${customerId}`;
            }

            return ax.get(path);
        }
    },
    seeka: {
        setupRequest(data: SeekaBrandSetupRequest): Promise<AxiosResponse<AsanaTaskResponse>> {
            try {
                return ax.post('/api/onboarding/seeka-brand-setup-request', data);
            } catch(e) {
                console.error('Failed to send Seeka brand setup request', e);
                throw e;
            }
        },
    },
    
    interactionFact: {
        postEventViewed(data: EventViewedRequestData): Promise<AxiosResponse<void>> {
            return ax.post('/api/interaction/event/view', data);
        },
        postEventClicked(data: EventClickedRequestData): Promise<AxiosResponse<void>> {
            return ax.post('/api/interaction/event/click', data);
        },
        postSectionViewed(data: SectionViewedRequestData): Promise<AxiosResponse<void>> {
            return ax.post('/api/interaction/section/view', data);
        },
        postSectionShowMoreClicked(data: SectionShowMoreClickedRequestData): Promise<AxiosResponse<void>> {
            return ax.post('/api/interaction/section/show-more', data);
        },
    },
    recommendation: {
        postRecommendations(options: RecommendationsOptions, overrides?: DeveloperOverrides): Promise<AxiosResponse<RecommendationsResponse>> {
            let path = '/api/recommendations';

            if (overrides && overrides.trackingId) {
                path += `?overrides.trackingId=${overrides.trackingId}`;
            }

            return ax.post(path, {
                options
            });
        }
    },
    session: {
        getSessionData(): Promise<AxiosResponse<SessionDataResponse>> {
            return ax.get('/api/session');
        },
        postEventBookmarked(data: EventBookmarkedRequestData): Promise<AxiosResponse<void>> {
            return ax.post('/api/session/bookmark', data);
        },
        deleteEventBookmarked(data: EventUnbookmarkedRequestData): Promise<AxiosResponse<void>> {
            return ax.delete('/api/session/bookmark', { data });
        },
        postLocationUpdated(data: LocationUpdatedRequestData): Promise<AxiosResponse<void>> {
            return ax.post('/api/session/location', data);
        },
        postPreferredBands(data: PreferredBandsRequestData): Promise<AxiosResponse<void>> {
            return ax.post('/api/session/bands', data);
        },
        postPreferredCategories(data: PreferredCategoriesRequestData): Promise<AxiosResponse<void>> {
            return ax.post('/api/session/categories', data);
        },
        postPreferredGenres(data: PreferredGenresRequestData): Promise<AxiosResponse<void>> {
            return ax.post('/api/session/genres', data);
        },
    },
}


import { defineComponent } from "vue";

export default defineComponent({
  name: "Heart",
  props: {
    filled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hasFocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasHover: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    className() {
      if (this.filled) return "heart filled";
      return "heart";
    },
    outlineClass() {
      if (this.hasFocus || this.hasHover) {
        return "orange";
      }
      return "white";
    },
  },
});

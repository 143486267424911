
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    const partners = [
      "ALHGroup",
      "AVC",
      "NightOwl",
      "CaravanIndustryAssociationOfAustralia",
      "CraftedBeerCiderFestival",
      "BigRedBash",
      "GoodThings",
      "ThisThat",
      "DAL",
      "Frontier",
      "Vibestown",
      "Summernats",
      "Supersonic",
      "SelectMusic",
      "EatonshillHotel",
      "CornerHotel",
      "TheGov",
      "TheZoo",
      "Crowbar",
      "FremantleArtsCentre",
      "MuseumCAA",
      "BrisbaneCityCouncil",
      "BigSound",
      "Awma",
      "TurnUp",
      "GoldCoastMusicAwards",
      "AustralianMusicWeek",
      "EventSummit",
      "SoundWest",
      "Qmas"
    ];
    return { partners };
  },
});

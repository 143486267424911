import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6507986c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "container",
  class: "container full"
}
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_primary_event = _resolveComponent("primary-event")!
  const _component_stack_item = _resolveComponent("stack-item")!
  const _component_stack = _resolveComponent("stack")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h2", {
          key: 0,
          id: _ctx.anchorLink
        }, _toDisplayString(_ctx.title), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createVNode(_component_stack, {
      columnCount: _ctx.columnCount,
      gutterHeight: 8,
      gutterWidth: 8,
      "item-count": _ctx.displayedEvents.length
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedEvents, (event, i) => {
          return (_openBlock(), _createBlock(_component_stack_item, {
            class: "img-container",
            key: i
          }, {
            default: _withCtx(() => [
              _createVNode(_component_primary_event, {
                "event-key": event.eventKey,
                name: event.name,
                url: event.url,
                when: event.when,
                where: event.where,
                image: event.image,
                status: event.status,
                "is-bookmarked": 
            _ctx.bookmarkedEventKeys && _ctx.bookmarkedEventKeys.includes(event.eventKey)
          ,
                onViewed: ($event: any) => (_ctx.onEventViewed(event.eventKey, i)),
                onBookmark: ($event: any) => (_ctx.onBookmarkEvent(event.eventKey, i)),
                onClicked: ($event: any) => (_ctx.onEventClicked(event.eventKey, i))
              }, null, 8, ["event-key", "name", "url", "when", "where", "image", "status", "is-bookmarked", "onViewed", "onBookmark", "onClicked"])
            ]),
            _: 2
          }, 1024))
        }), 128)),
        (_ctx.hasMoreEvents)
          ? (_openBlock(), _createBlock(_component_stack_item, { key: 0 }, {
              default: _withCtx(() => [
                _createElementVNode("button", {
                  class: "show-more",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleShowMoreButton && _ctx.handleShowMoreButton(...args)))
                }, " Show 4 more ")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["columnCount", "item-count"])
  ], 512))
}

import { defineComponent, PropType } from "vue";
import FilterListItem from "./FilterListItem.vue";

type AisRefinementListItem = {
  value: string;
  label: string;
  count: number;
  isRefined: boolean;
};

export default defineComponent({
  name: "FilterList",
  components: {
    FilterListItem,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Object as PropType<AisRefinementListItem[]>,
      required: true,
    },
    refine: {
      type: Function as PropType<(itemValue: string) => void>,
      required: true,
    },
  },
});

type Web2LeadFormData = {
    [key: string]: string;
    oid: string;
    retURL: string;
    lead_source: string;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    How_many_tickets_will_be_for_sale__c: string;
    Where_is_the_event__c: string;
    description: string;
}

export const web2Lead = function (fields: Web2LeadFormData): void {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8";
    for (const fieldName in fields) {
        const theInput = document.createElement("input");
        theInput.name = fieldName;
        theInput.value = fields[fieldName];
        theInput.setAttribute("type", "hidden");
        form.appendChild(theInput);
    }
    document.body.appendChild(form);
    form.submit();
}
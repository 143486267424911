import config from "@/config";

function getUtmParams() {
    return {
        utm_source: window.location.hostname,
        utm_medium: 'referral',
        utm_campaign: 'create_event',
        utm_content: pageIdentifier(window.location.pathname)
    };
}

function pageIdentifier(pathname : string) {
    if (pathname === '/') {
        return 'homepage';
    } else {
        return pathname.replace(/^\//, '').replace(/\//g, '_');
    }
}

function primaryCtaUrl(baseUrl: string, returnUrl: string, utmParams: Record<string, string>, posthogDistinctId: string): string {
    const utmQuery = new URLSearchParams(utmParams).toString();
    const posthogDistinctIdQuery = posthogDistinctId ? `&phId=${posthogDistinctId}` : '';
    return `${baseUrl}/Account/Register?${utmQuery}${posthogDistinctIdQuery}&returnUrl=${encodeURIComponent(returnUrl)}`;
}

const studioEventCreateUrl = config.STUDIO_BASE_URL + '/events/create';
const primaryCtaLink = (posthogDistinctId: string) => primaryCtaUrl(config.IDENTITY_AUTHORITY, studioEventCreateUrl, getUtmParams(), posthogDistinctId)

export const heroContent = {
    heading: 'Think Outside The Box Office',
    contactCta: {
        text: 'Contact us',
        link: '#get-in-contact'
    },
    primaryCta: {
        text: 'Create an event',
        link: primaryCtaLink
    }
}


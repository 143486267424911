import posthog from "posthog-js";
import config from "@/config";

function initPosthog() {
    if (config.POSTHOG_API_KEY 
        && config.POSTHOG_API_HOST) {
        return posthog.init(config.POSTHOG_API_KEY, {
            api_host: config.POSTHOG_API_HOST,
            autocapture: {
                url_allowlist: [/\/ticket-your-event$/],
            },
            capture_pageview: false,
            capture_pageleave: false,
            loaded: posthog => {
                if (process.env.NODE_ENV === "development") posthog.debug();
            }
        });
    }
}

export const $posthog = initPosthog();

export default {
    install(app : any) {
        app.config.globalProperties.$posthog = $posthog
    }
}
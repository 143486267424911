import axios, { AxiosResponse } from 'axios';
import { AnalyticsApi, InPageAnalyticsData, InPageAnalyticsResponse } from "@/analytics/types";
import { checkOztixDeveloperCookie } from '@/library/cookieUtil';

const ax = axios.create({
    baseURL: 'https://inpageanalytics.ticketsolutions.com.au',
    withCredentials: true, // send cookies 🍪
})

export const analyticsApi: AnalyticsApi = {
    fetchTrackingId(): Promise<AxiosResponse<InPageAnalyticsResponse>> | null {
        const isOztixDeveloper = checkOztixDeveloperCookie();
        if (isOztixDeveloper) return null;

        const dataToSend = {} as InPageAnalyticsData

        dataToSend.event = "track";
        dataToSend.action = "get_cookie";

        // native window/document elements
        dataToSend.location = window.location || {}
        dataToSend.referrer = document.referrer || ""
        dataToSend.cookies = document.cookie || ""

        dataToSend.dataLayer = {} // from GTM
        return ax.post('/notify', dataToSend, { headers: { 'Content-Type': 'application/json' } });
    },
    sendPageView(): Promise<AxiosResponse<InPageAnalyticsResponse>> | null {
        const isOztixDeveloper = checkOztixDeveloperCookie();
        if (isOztixDeveloper) return null;

        const dataToSend = {} as InPageAnalyticsData

        dataToSend.event = "track";
        dataToSend.action = "page_view";

        // native window/document elements
        dataToSend.location = window.location || {}
        dataToSend.referrer = document.referrer || ""
        dataToSend.cookies = document.cookie || ""

        dataToSend.dataLayer = {} // from GTM
        return ax.post('/notify', dataToSend, { headers: { 'Content-Type': 'application/json' } });
    }
}

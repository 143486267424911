import { createLogger, createStore } from "vuex"
import session, { SessionState } from "@/store/modules/session";
import recommendations, { RecommendationsState } from "@/store/modules/recommendations";
import content, { ContentState } from "@/store/modules/content"

const debug = process.env.NODE_ENV !== 'production'

export type State = {
    session: SessionState;
    recommendations: RecommendationsState;
    content: ContentState;
}

const store = createStore<State>({
    modules: {
        session,
        recommendations,
        content
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})

export default store


import moment from "moment-timezone";
import { EventStatus } from "@/domain/types";
import { Venue, ViewEvent } from "@/api/types";

declare global {
    interface Date {
        isValid: () => boolean;
    }
}

Date.prototype.isValid = function (): boolean {
    // If the date object is invalid it
    // will return 'NaN' on getTime() 
    // and NaN is never equal to itself.
    return this.getTime() === this.getTime();
};

function isValidTimezone(timezone: string) {
    if (typeof (timezone) !== "string") return false;
    const timezones = moment.tz.names();
    return timezones.includes(timezone);
}

export const formatDate = (dateString: string, timezone: string, currentDate: Date | null = null): string => {
    const today = currentDate ?? new Date();
    if (!today.isValid()) {
        return "Invalid date"
    }

    const date = new Date(dateString);

    if (!date.isValid()) {
        return "Invalid date"
    }

    if (!isValidTimezone(timezone)) {
        return "Invalid date"
    }

    if (date < today || date.getFullYear() !== today.getFullYear()) {
        return moment.utc(dateString).tz(timezone).format("ddd, D MMM YY");
    }
    return moment.utc(dateString).tz(timezone).format("ddd, D MMM");
}

export const getDay = (dateString: string, timezone: string): string => {
    const date = new Date(dateString);

    if (!date.isValid()) {
        return "Invalid date"
    }

    if (!isValidTimezone(timezone)) {
        return "Invalid date"
    }
    return moment.utc(dateString).tz(timezone).format("ddd");
}

export const getDate = (dateString: string, timezone: string, currentDate: Date | null = null): string => {
    const today = currentDate ?? new Date();
    if (!today.isValid()) {
        return "Invalid date"
    }

    const date = new Date(dateString);

    if (!date.isValid()) {
        return "Invalid date"
    }

    if (!isValidTimezone(timezone)) {
        return "Invalid date"
    }

    if (date < today || date.getFullYear() !== today.getFullYear()) {
        return moment.utc(dateString).tz(timezone).format("D MMM YY");
    }
    return moment.utc(dateString).tz(timezone).format("D MMM");
}

export const getTime = (dateString: string, timezone: string): string => {
    const date = new Date(dateString);

    if (!date.isValid()) {
        return "Invalid date"
    }

    if (!isValidTimezone(timezone)) {
        return "Invalid date"
    }

    return moment.utc(dateString).tz(timezone).format("h:mma");
}

export const formatVenue = (venue: Venue): string => {
    return `${venue.name}, ${venue.state}`
}

export const getStatus = (event: ViewEvent, currentTime: number): EventStatus | "" => {
    // FIXME: there can be more than 1 status
    if (event.isCancelled) return "cancelled";
    if (event.isPostponed) return "postponed";
    if (event.isRescheduled) return "rescheduled";
    if (
        !event.availability &&
        event.availabilityDate &&
        new Date(event.availabilityDate).getTime() < currentTime
    ) {
        return "sold out";
    }
    return "";
}
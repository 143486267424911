
import { defineComponent } from "vue";

export default defineComponent({
  name: "PromotedSection",
  emits: ["click", "sectionViewed"],
  props: {
    id: {
      type: String,
    },
    url: {
      type: String,
    },
    imgSrc: {
      type: String,
    },
    textDescription: {
      type: String,
    },
  },
  data() {
    return {
      observer: null as null | IntersectionObserver,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      threshold: [0, 0.9],
    });

    this.$nextTick(() => {
      if (
        this.$refs.container instanceof Element &&
        this.observer instanceof IntersectionObserver
      ) {
        this.observer.observe(this.$refs.container);
      }
    });
  },
  methods: {
    handleIntersection(entries: IntersectionObserverEntry[]) {
      for (const { isIntersecting, intersectionRatio } of entries) {
        if (!isIntersecting) {
          return;
        }

        if (intersectionRatio > 0.8) {
          this.$emit("sectionViewed");
        }
      }
    },
  },
});

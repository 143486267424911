
import { defineComponent } from "vue";
import { useHead } from "@vueuse/head";
import CompactLayout from "@/layouts/CompactLayout.vue";
import B2bHero from "./B2bHero.vue";
import B2bIntegrations from "./B2bIntegrations.vue";
import B2bTestimonials from "./B2bTestimonials.vue";
import B2bDetailedOffering from "./B2bDetailedOffering.vue";
import B2bPartners from "./B2bPartners.vue";
import B2bOurStaff from "./B2bOurStaff.vue";
import B2bContactForm from "./B2bContactForm.vue";
import { detailedContent } from "@/content/b2b/detailed-content";

export default defineComponent({
  components: {
    CompactLayout,
    B2bHero,
    B2bIntegrations,
    B2bTestimonials,
    B2bDetailedOffering,
    B2bPartners,
    B2bOurStaff,
    B2bContactForm,
  },
  mounted() {
    useHead({
      title: "Ticket Your Event — Oztix",
      meta: [
        {
          name: "description",
          content:
              "Oztix is the home of personalised experiences. Find the perfect event just for you.",
        },
      ],
    });
    this.$nextTick(() => {
      this.$posthog?.capture('$pageview')
    });
  },
  data() {
    return {
      content: detailedContent,
    };
  },
});

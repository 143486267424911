const config = {
    ENVIRONMENT_TYPE: '#{EnvironmentType}',
    APPLICATION_NAME: 'TicketSolutions.Personalisation',
    APPLICATION_VERSION: '#{ApplicationVersion}',
    API_BASE_URI: '#{ApiBaseUri}',
    STUDIO_BASE_URL: '#{StudioBaseUrl}',
    BASE_URL: '#{BaseUrl}',
    IDENTITY_AUTHORITY: '#{IdentityClientAuthority}',
    ALGOLIA_APPLICATION_ID: '#{AlgoliaApplicationId}',
    ALGOLIA_PERSONALISATION_API_KEY: '#{AlgoliaPersonalisationApiKey}',
    ALGOLIA_AUTOCOMPLETE_API_KEY: '#{AlgoliaAutocompleteApiKey}',
    ALGOLIA_REFERENCE_API_KEY: '#{AlgoliaReferenceApiKey}',
    COOKIE_DOMAIN: 'oztix.com.au',
    SALESFORCE_WEB_2_CASE_ORG_ID: '#{SalesforceWeb2CaseOrgId}',
    SALESFORCE_WEB_2_CASE_RECORD_TYPE: '#{SalesforceWeb2CaseRecordType}',
    SALESFORCE_WEB_2_CASE_RET_URL: '#{SalesforceWeb2CaseRetUrl}',
    SALESFORCE_WEB_2_CASE_BRAND: '#{SalesforceWeb2CaseBrand}',
    SALESFORCE_WEB_2_LEAD_OID: '#{SalesforceWeb2LeadOid}',
    SALESFORCE_WEB_2_LEAD_RET_URL: '#{SalesforceWeb2LeadRetUrl}',
    SALESFORCE_WEB_2_LEAD_BRAND: '#{SalesforceWeb2LeadBrand}',
    SUPPRESS_VIEW_IMPRESSIONS: '#{SuppressViewImpressions}',
    POSTHOG_API_KEY: '#{PostHogApiKey}',
    POSTHOG_API_HOST: '#{PostHogApiHost}',
}

export default config
import { RecommendSection, ViewEvent } from "@/api/types";
import { Section } from "@/components/Section/types";
import { OztixEvent } from "@/domain/types";
import { formatDate, formatVenue, getStatus } from "@/library/eventFormatUtil";

export const mapCatalogToEventsInSection = function (
    section: RecommendSection, catalog: ViewEvent[]
): null | Array<OztixEvent> {
    const map = new Map();
    section.events.forEach((sectionEv) =>
        map.set(sectionEv.eventKey, {} as OztixEvent)
    );

    if (catalog) {
        catalog.forEach((catEv: ViewEvent) => {
            const inMap = map.get(catEv.eventKey);
            if (inMap) {
                Object.assign(inMap, {
                    eventKey: catEv.eventKey,
                    name: catEv.eventName,
                    url: catEv.eventUrl,
                    when: formatDate(catEv.dateStart, catEv.venue.timezone),
                    where: formatVenue(catEv.venue),
                    image: catEv.homepageImage + "&autotrim=",
                    studioImage: catEv.eventImage1,
                    status: getStatus(catEv, new Date().getTime()),
                    bookmarked: false,
                } as OztixEvent);
            }
        });
    }

    return Array.from(map.values());
}

/* Fisher-Yates algo from https://javascript.info/array-methods#shuffle-an-array */
function shuffle(array: unknown[]): void {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

export const randomiseFirstTenEvents = (section: Section): void => {
    if (section.events) {
        const firstTenEvents = section.events.splice(0, 10);
        shuffle(firstTenEvents);
        section.events = [...firstTenEvents, ...section.events]
    }
}
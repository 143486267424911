
import { useHead } from "@vueuse/head";
import marked from "marked";
import DOMPurify from "dompurify";
import { defineComponent } from "@vue/runtime-core";
import Layout from "@/layouts/Layout.vue";
import { SalesforceContactForm } from "@/components";

export default defineComponent({
  name: "Contact",
  components: {
    Layout,
    SalesforceContactForm
  },
  props: {
    title: {
      type: String,
      required: true,
      default: "Not found"
    },
    content: {
      type: String,
      required: true,
      default: () => "Sorry, we couldn't find the page you're looking for."
    }
  },
  computed: {
    htmlContent() {
      const dirty = marked(this.content);
      const clean = DOMPurify.sanitize(dirty);
      return clean;
    }
  },
  mounted() {
    useHead({
      title: "Customer Support — Oztix",
      meta: [
        {
          name: "description",
          content:
            "Oztix is the home of personalised experiences. Find the perfect event just for you."
        }
      ]
    });
  }
});

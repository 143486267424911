
import { defineComponent } from "vue";
import { IconChevronLeft, IconChevronRight } from "@/components/Icon";

export default defineComponent({
  name: "HorizontalScroll",
  components: {
    IconChevronLeft,
    IconChevronRight,
  },
  props: {
    columnCount: {
      // number of items to show at a time
      type: Number,
      required: false,
      default: 5,
    },
    currentIndex: {
      type: Number,
      required: true,
      default: 0,
    },
    showControls: {
      // show arrows
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      scrollItems: null as null | HTMLCollection,
      containerWidth: 0,
      gutterWidth: 10,
      columnWidth: 0,
      showNavControls: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.reflow);
    let el = this.$refs.container;
    if (el instanceof HTMLElement) {
      this.scrollItems = el.children;

      el.addEventListener("mouseenter", this.handleMouseEnter);

      el.style.paddingLeft = `${this.gutterWidth}px`;
      el.style.paddingRight = `${this.gutterWidth}px`;
      el.style.columnGap = `${this.gutterWidth}px`;

      this.update();
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.reflow);
  },
  watch: {
    currentIndex() {
      this.$nextTick(this.moveToCurrentIndex);
    },
    columnCount() {
      this.$nextTick(this.reflow);
    },
  },
  computed: {
    showNext(): boolean {
      if (!this.scrollItems) return false;
      if (this.currentIndex < this.scrollItems.length - this.columnCount)
        return true;
      return false;
    },
    showPrev(): boolean {
      if (!this.scrollItems) return false;
      if (this.currentIndex <= 0) return false;
      return true;
    },
  },
  methods: {
    update(): void {
      this.$nextTick(this.reflow);
    },
    updateDimensions() {
      this.containerWidth = this.getContainerWidth(); // sets column count and column width
      this.columnWidth = this.getColumnWidth();
    },
    getColumnWidth(): number {
      return (
        (this.containerWidth - (this.columnCount + 3) * this.gutterWidth) /
        this.columnCount
      );
    },
    getContainerWidth(): number {
      return this.$refs.container
        ? (this.$refs.container as HTMLElement).clientWidth
        : 0;
    },
    moveToCurrentIndex() {
      let el = this.$refs.container;
      if (el instanceof HTMLElement) {
        el.style.gridAutoColumns = `${this.columnWidth}px`;
        el.style.transform = `translate(${
          this.currentIndex * -1 * (this.columnWidth + this.gutterWidth) +
          this.gutterWidth
        }px, 0px)`;
      }
    },
    reflow(): void {
      const newWidth = this.getContainerWidth();
      this.containerWidth = newWidth;
      this.updateDimensions();

      this.$emit("reflow", {
        containerWidth: this.containerWidth,
      });

      this.$nextTick(() => {
        this.moveToCurrentIndex();
      });
    },
    handleNext() {
      this.$emit("next");
    },
    handlePrev() {
      this.$emit("prev");
    },
    onTouchStart(e: Event) {
      this.$emit("touchstart", e);
    },
    onTouchEnd(e: Event) {
      this.$emit("touchend", e);
    },
    handleMouseEnter() {
      this.showNavControls;
    },
  },
});


import { defineComponent } from "vue";
import { IconHeart } from "@/components";

export default defineComponent({
  name: "BookmarkButton",
  components: {
    IconHeart,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: "Bookmark",
    },
    labelBookmarked: {
      type: String,
      required: false,
      default: "Remove bookmark",
    },
    isBookmarked: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      hasFocus: false,
      hasHover: false,
    };
  },
  mounted() {
    const button = this.$refs.btn;
    if (button instanceof HTMLButtonElement) {
      button.addEventListener("focusin", this.setHasFocus);
      button.addEventListener("focusout", this.removeHasFocus);
      button.addEventListener("mouseenter", this.setHasHover);
      button.addEventListener("mouseleave", this.removeHasHover);
    }
  },
  unmounted() {
    const button = this.$refs.btn;
    if (button instanceof HTMLButtonElement) {
      button.removeEventListener("focusin", this.setHasFocus);
      button.removeEventListener("focusout", this.removeHasFocus);
      button.removeEventListener("mouseenter", this.setHasHover);
      button.removeEventListener("mouseleave", this.removeHasHover);
    }
  },
  methods: {
    setHasFocus() {
      this.hasFocus = true;
    },
    removeHasFocus() {
      this.hasFocus = false;
    },
    setHasHover() {
      this.hasHover = true;
    },
    removeHasHover() {
      this.hasHover = false;
    },
  },
});


import { defineComponent } from "vue";
import { CloseButton } from "@/components";

export default defineComponent({
  name: "BaseModal",
  components: {
    CloseButton,
  },
  props: {
    buttonLabel: {
      type: String,
      required: true,
      default: "Open modal",
    },
    modalHeading: {
      type: String,
      required: true,
      default: "Modal heading",
    },
    initialFocusId: {
      type: String,
      required: true,
      default: "",
      validator(value: string): boolean {
        return value.startsWith("#");
      },
    },
  },
  data() {
    return {
      isOpen: false,
      backdrop: null as null | HTMLDivElement,
      body: null as null | HTMLBodyElement,
    };
  },
  mounted() {
    this.backdrop = this.$refs.backdrop as HTMLDivElement;
    this.body = document.getElementsByTagName("body")[0];
  },
  methods: {
    openModal() {
      if (!this.isOpen) {
        this.isOpen = true;
        this.makeAppInaccessible();
        this.$nextTick(() => {
          this.showBackdrop();
          window.scrollTo(0, 0); // to allow the modal to be scrolled, we must position it at the top of the page - so, we also need to scroll to it
        });
      }
    },
    closeModal() {
      if (this.isOpen) {
        this.isOpen = false;
      }
      this.makeAppAccessible();
      this.$nextTick(() => {
        this.hideBackdrop();
      });
    },
    makeAppInaccessible() {
      const app = document.getElementById("app");
      if (app) {
        app.setAttribute("aria-hidden", "true");
        app.setAttribute("tabindex", "-1");
        app.setAttribute("inert", "true");
      }
      this.body && this.body.classList.add("has-dialog");
    },
    makeAppAccessible() {
      const app = document.getElementById("app");
      if (app) {
        app.removeAttribute("aria-hidden");
        app.removeAttribute("tabindex");
        app.removeAttribute("inert");
      }
      this.body && this.body.classList.remove("has-dialog");
    },
    showBackdrop() {
      this.backdrop && this.backdrop.classList.add("active");
    },
    hideBackdrop() {
      this.backdrop && this.backdrop.classList.remove("active");
    },
  },
});

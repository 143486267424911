
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    backgroundImage: {
      type: String,
      required: true,
      default: () => require("@/assets/b2b/our-experience-bg.jpg"),
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#000",
    },
    textAlign: {
      type: String as PropType<"left" | "right" | "center">,
      required: false,
      default: "left",
    },
    scrollTo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore;
      if (this.showMore) {
        this.$router.push(this.scrollTo);
      }
    },
  },
});

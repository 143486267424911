
import { defineComponent, PropType } from "vue";
import { isValidUrl, isValidEventStatus } from "@/library/validationUtil";
import { EventStatus } from "@/domain/types";
import { BookmarkButton } from "@/components";
// eslint-disable-next-line
const ColorThief = require("../../../node_modules/colorthief/dist/color-thief.umd.js");
import { computeHeroColours } from "@/library/colourUtil";

export default defineComponent({
  name: "HeroEvent",
  components: {
    BookmarkButton,
  },
  emits: ["viewed", "bookmark", "clicked"],
  props: {
    eventKey: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
      validator: (url) => isValidUrl(url),
    },
    when: {
      type: String,
      default: "",
    },
    where: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "/images/placeholder.png",
      validator: (image) => isValidUrl(image),
    },
    status: {
      type: String as PropType<EventStatus>,
      default: null,
      validator: (status) => isValidEventStatus(status),
    },
    isBookmarked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      observer: null as null | IntersectionObserver,
      backgroundColour: "#0077c9",
      textColour: "#fff",
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      threshold: [0, 0.9],
      rootMargin: "0px 0px 0px 500px",
    });

    this.$nextTick(() => {
      if (
        this.$refs.el instanceof Element &&
        this.observer instanceof IntersectionObserver
      ) {
        this.observer.observe(this.$refs.el);
        this.loadImage();
      }
    });
  },
  methods: {
    async handleIntersection(entries: IntersectionObserverEntry[]) {
      for (let i = 0; i < entries.length; i++) {
        if (entries[i].isIntersecting === false) {
          return;
        }

        if (entries[i].intersectionRatio > 0.8) {
          this.$emit("viewed");
        }
      }
    },
    async loadImage() {
      if (this.$refs.eventImage instanceof HTMLImageElement) {
        const src = this.$refs.eventImage.getAttribute("lazy-src");
        if (src) {
          // this is required for setting dynamic colours for x-origin images
          // otherwise we get the error: Could not load dynamic colours
          // DOMException: Failed to execute 'getImageData' on 'CanvasRenderingContext2D':
          // The canvas has been tainted by cross-origin data.
          try {
            let blob = await fetch(src).then((r) => r.blob());
            let dataUrl: string | ArrayBuffer | null = await new Promise(
              (resolve, reject) => {
                let reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
                reader.readAsDataURL(blob);
              }
            );
            this.$refs.eventImage.src = dataUrl ? dataUrl.toString() : src;

            if (dataUrl) {
              this.setDynamicColours(this.$refs.eventImage);
            }

            this.$refs.eventImage.removeAttribute("lazy-src"); // stop from loading multiple times
          } catch (e) {
            console.error("ERROR", e);
          }
        }
      }
    },
    setDynamicColours(img: HTMLImageElement) {
      const colorThief = new ColorThief();
      if (img.complete) {
        const rgb: number[] = colorThief.getColor(img);
        const { backgroundColour, textColour } = computeHeroColours(
          rgb[0],
          rgb[1],
          rgb[2]
        );
        this.backgroundColour = backgroundColour;
        this.textColour = textColour;
      } else {
        img.addEventListener("load", async () => {
          try {
            const rgb: number[] = colorThief.getColor(img);
            const { backgroundColour, textColour } = computeHeroColours(
              rgb[0],
              rgb[1],
              rgb[2]
            );
            this.backgroundColour = backgroundColour;
            this.textColour = textColour;
          } catch (e) {
            console.warn("Could not load dynamic colours", e);
          }
        });
      }
    },
  },
});

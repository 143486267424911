export const askForLocation = async function (options?: { timeout: number; maximumAge: number; enableHighAccuracy: boolean; }): Promise<GeolocationPosition> {
    const defaultGeoOptions = {
        timeout: 15 * 1000, // (ms) time before callback error is thrown
        maximumAge: 1000 * 60 * 5, // maximum cached position age (ms) - saves firing up device's GPS
        enableHighAccuracy: false // approximate location is fine
    }

    return new Promise((resolve, reject) => {
        if (!("geolocation" in navigator)) {
            reject(new Error('Geolocation is not available.'));
        }

        navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
                return resolve(position);
            },
            (error: GeolocationPositionError) => reject(error),
            options ?? defaultGeoOptions
        );
    })
};

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_list_item = _resolveComponent("filter-list-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: `${item.value}-${item.isRefined}`
        }, [
          _createVNode(_component_filter_list_item, {
            modelValue: item.value,
            "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
            checked: item.isRefined,
            onClick: _withModifiers(($event: any) => (_ctx.refine(item.value)), ["prevent"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.label) + " (" + _toDisplayString(item.count) + ") ", 1)
            ]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "checked", "onClick"])
        ]))
      }), 128))
    ])
  ], 64))
}
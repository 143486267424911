
import {defineComponent} from "vue";
import {mapState} from "vuex";
import {LocationQuery} from "vue-router";
import {useHead} from "@vueuse/head";
import algoliasearch from "algoliasearch/lite";
import config from "@/config";
import Layout from "@/layouts/Layout.vue";
import {SearchEvent, SkipLink} from "@/components";
import FilterList from "@/components/Search/FilterList.vue";
import CloseButton from "@/components/Button/CloseButton.vue";
import {getDay, getDate} from "@/library/eventFormatUtil";

interface AlgoliaRouter {
  read(): LocationQuery;
  write(routeState: LocationQuery): void;
  createURL(routeState: LocationQuery): string;
  onUpdate(cb: (routeState: LocationQuery) => void): void;
  dispose(): void;
  _onPopState(event: PopStateEvent): void;
}

export default defineComponent({
  components: {
    Layout,
    FilterList,
    SearchEvent,
    CloseButton,
    SkipLink,
  },
  data() {
    const indexName = "prod_oztix_eventguide";
    const vueRouter = this.$router;

    return {
      indexName,
      containerWidth: 0,
      isMobile: false,
      index: algoliasearch(
          config.ALGOLIA_APPLICATION_ID,
          config.ALGOLIA_PERSONALISATION_API_KEY
      ).initIndex(indexName),
      getDay: getDay,
      getDate: getDate,
      searchClient: algoliasearch(
          config.ALGOLIA_APPLICATION_ID,
          config.ALGOLIA_PERSONALISATION_API_KEY
      ),
      showFilters: true,
      routing: {
        router: {
          read() {
            /* read from the URL and return a routeState */
            return vueRouter.currentRoute.value.query;
          },
          write(routeState: LocationQuery) {
            /* write to the URL */
            vueRouter.push({
              query: routeState,
            });
          },
          createURL(routeState: LocationQuery) {
            /* return a URL as a string */
            return vueRouter.resolve({
              query: routeState,
            }).href;
          },
          onUpdate(cb: (routeState: LocationQuery) => void) {
            /* call this callback whenever the URL changed externally */
            this._onPopState = (event: PopStateEvent) => {
              const routeState = event.state;

              if (!routeState) {
                cb(this.read());
              } else {
                cb(routeState);
              }
            };
            window.addEventListener("popstate", this._onPopState);
          },
          dispose() {
            window.removeEventListener("popstate", this._onPopState);
          },
        } as AlgoliaRouter,
        stateMapping: {
          stateToRoute(uiState: any) {
            const indexUiState = uiState[indexName];
            return {
              q: indexUiState.query,
              states:
                  indexUiState.refinementList &&
                  indexUiState.refinementList["Venue.State"],
              categories:
                  indexUiState.refinementList &&
                  indexUiState.refinementList.Categories,
              artists:
                  indexUiState.refinementList &&
                  indexUiState.refinementList.Bands,
              venues:
                  indexUiState.refinementList &&
                  indexUiState.refinementList["Venue.Name"],
              page: indexUiState.page,
            };
          },
          routeToState(routeState: any) {
            return {
              [indexName]: {
                query: routeState.q,
                refinementList: {
                  ["Venue.State"]: routeState.states,
                  Categories: routeState.categories,
                  Bands: routeState.artists,
                  ["Venue.Name"]: routeState.venues,
                },
                page: routeState.page,
              },
            };
          },
        },
      },
    };
  },
  computed: {
    ...mapState("session", ["bookmarkedEventKeys"]),
    query() {
      return this.$router.currentRoute.value.query.q || "";
    },
  },
  mounted() {
    useHead({
      title: this.query ? `${this.query} — Oztix` : "Events — Oztix",
      meta: [
        {
          name: "description",
          content:
              "Oztix is the home of personalised experiences. Find the perfect event just for you.",
        },
      ],
    });
    this.reflow();
    window.addEventListener("resize", this.reflow);
  },
  unmounted() {
    window.removeEventListener("resize", this.reflow);
  },
  methods: {
    reflow() {
      const newWidth = this.getContainerWidth();

      if (newWidth === this.containerWidth) return;

      if (newWidth >= 600) {
        this.isMobile = false;
        this.showFilters = true;
      } else {
        this.isMobile = true;
        this.showFilters = false;
      }

      this.containerWidth = newWidth;
    },
    getContainerWidth(): number {
      return this.$refs.container
          ? (this.$refs.container as HTMLDivElement).clientWidth
          : 0;
    },
    onEventViewed(eventKey: number, index: number) {
      const query = this.routing.router.read();
      this.$store.dispatch("session/eventViewed", {
        eventKey: eventKey,
        interactionTarget: `search-results--${JSON.stringify(
            query
        )}--index#${index}`,
      });
    },
    onBookmarkEvent(eventKey: number, index: number) {
      const query = this.routing.router.read();
      this.$store.dispatch("session/bookmarkEvent", {
        eventKey: eventKey,
        interactionTarget: `search-results--${JSON.stringify(
            query
        )}--index#${index}`,
      });
    },
    onEventClicked(eventKey: number, index: number) {
      const query = this.routing.router.read();
      this.$store.dispatch("session/eventClicked", {
        eventKey: eventKey,
        interactionTarget: `search-results--${JSON.stringify(
            query
        )}--index#${index}`,
      });
    },
    getEventUrlWithSearchUtm(eventUrl: string) {
      const searchTerm = this.query;
      return eventUrl +
          (searchTerm
              ? ((eventUrl.includes('?') ? '&' : '?') + `utm_content=search&utm_term=${searchTerm}`)
              : '');
    },
  },
});

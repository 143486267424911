import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { "aria-labelledby": "think-outside-the-box-office" }
const _hoisted_2 = { class: "ticket-your-event--hero--content" }
const _hoisted_3 = { id: "think-outside-the-box-office" }
const _hoisted_4 = { class: "call-to-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skip_link = _resolveComponent("skip-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: "ticket-your-event--hero",
    style: _normalizeStyle({
      'background-image': `url(${require('@/assets/b2b/b2b-hero.jpeg')})`
    })
  }, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.content.heading), 1),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_skip_link, {
            class: "primary-button",
            hash: _ctx.content.contactCta.link
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.content.contactCta.text), 1)
            ]),
            _: 1
          }, 8, ["hash"]),
          _createElementVNode("a", {
            class: "secondary-button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.createEventClick && _ctx.createEventClick(...args)))
          }, _toDisplayString(_ctx.content.primaryCta.text), 1)
        ])
      ])
    ])
  ], 4))
}
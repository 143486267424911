import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "autocomplete__container" }
const _hoisted_3 = { class: "autocomplete__container--form" }
const _hoisted_4 = { ref: "autocompleteDiv" }
const _hoisted_5 = { class: "autocomplete__container--action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", {
      class: _normalizeClass(_ctx.visuallyHideLabel ? 'sr-only' : ''),
      for: _ctx.autocompleteId
    }, _toDisplayString(_ctx.label), 11, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, null, 512)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: "primary-button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onActionButtonClicked && _ctx.onActionButtonClicked(...args)))
        }, _toDisplayString(_ctx.actionLabel), 1)
      ])
    ])
  ], 64))
}
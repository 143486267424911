import { analyticsApi } from '@/analytics/analyticsApi';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from "uuid"
import { CommitFn } from '@/store/types';
import { api } from '@/api';
import { GeoInfo, LocationType } from '@/api/types';
import config from '@/config';
import { BandKey, CategoryKey, GenreKey, OztixEvent } from '@/domain/types';
import { State } from '../index'
import { Section } from '@/components/Section/types';
import { formatDate, formatVenue, getStatus } from '@/library/eventFormatUtil';

export type SessionState = {
    version: number;
    sessionId: null | string;
    trackingId: null | string;
    bookmarkedEventKeys: number[]; // Array of EventKeys
    lastSetLocationType: LocationType;
    lastSetGeo: null | GeoInfo;
    lastSetPostcode: null | string;
    lastSetLocationVersion: number;
    preferences: {
        bands: Array<BandKey>;
        categories: Array<CategoryKey>;
        genres: Array<GenreKey>;
    },
    preferencesVersion: number;
    isDeveloper: boolean;
    isDonotfollow: boolean;
}

//initial state
const state = (): SessionState => ({
    version: 0,
    sessionId: null,
    trackingId: null,
    bookmarkedEventKeys: [],
    lastSetLocationType: LocationType.None,
    lastSetGeo: null,
    lastSetPostcode: null,
    lastSetLocationVersion: 0,
    preferences: {
        bands: [],
        categories: [],
        genres: [],
    },
    preferencesVersion: 0,
    isDeveloper: false,
    isDonotfollow: false
})

// getters
const getters = {
    isLocationSet: (state: SessionState): boolean => {
        if (state.lastSetLocationType === LocationType.None) return false
        if (state.lastSetLocationType === LocationType.Postcode && state.lastSetPostcode !== null) return true
        if (state.lastSetLocationType === LocationType.Geo && state.lastSetGeo !== null) return true
        return false
    },
    preferredBands(state: SessionState): BandKey[] {
        return state.preferences.bands;
    },
    preferredCategories(state: SessionState): CategoryKey[] {
        return state.preferences.categories;
    },
    preferredGenres(state: SessionState): GenreKey[] {
        return state.preferences.genres;
    },
    bookmarkedEvents(state: SessionState, getters: any, rootState: State): Section {
        const events = [] as OztixEvent[]

        if (rootState.recommendations.catalog) {
            rootState.recommendations.catalog.forEach((catEv) => {
                if (state.bookmarkedEventKeys.includes(catEv.eventKey)) {
                    events.push({
                        eventKey: catEv.eventKey,
                        name: catEv.eventName,
                        url: catEv.eventUrl,
                        when: formatDate(catEv.dateStart, catEv.venue.timezone),
                        where: formatVenue(catEv.venue),
                        image: catEv.homepageImage + "&autotrim=",
                        status: getStatus(catEv, new Date().getTime()),
                        bookmarked: true,
                    } as OztixEvent);
                }
            });
        }

        const bookmarkedSection: Section = {
            title: 'Your saved events',
            anchorLink: 'saved-events',
            events
        };

        return bookmarkedSection
    }
}

const SESSION_ID_COOKIE = 'ot_sessionid';
const DEVELOPER_COOKIE = 'ot_developer';
const DONOTFOLLOW_COOKIE = 'ot_donotfollow';

// actions
const actions = {
    fetchSessionId({ commit }: { commit: CommitFn<string> }): void {
        if (!navigator.cookieEnabled) {
            // TODO: Please enable cookies UI prompt
            console.warn("Please enable cookies");
        } else {
            let sessionId = Cookies.get(SESSION_ID_COOKIE);
            if (!sessionId) {
                sessionId = uuidv4();
                Cookies.set(SESSION_ID_COOKIE, sessionId, { expires: 365, domain: config.COOKIE_DOMAIN, secure: true });
            }
            commit('SET_SESSION_ID', sessionId);
        }
    },
    checkIsDeveloper({ commit }: { commit: CommitFn<boolean> }): void {
        if (!navigator.cookieEnabled) {
            // TODO: Please enable cookies UI prompt
            console.warn("Please enable cookies");
        } else {
            const developerCookie = Cookies.get(DEVELOPER_COOKIE);

            if (developerCookie) {
                commit('SET_IS_DEVELOPER', true)
            } else {
                commit('SET_IS_DEVELOPER', false)
            }
        }
    },
    unsetDeveloperCookie({ commit }: { commit: CommitFn<boolean> }): void {
        if (!navigator.cookieEnabled) {
            // TODO: Please enable cookies UI prompt
            console.warn("Please enable cookies");
        } else {
            const developerCookie = Cookies.get(DEVELOPER_COOKIE);

            if (developerCookie) {
                Cookies.remove(DEVELOPER_COOKIE)
                commit('SET_IS_DEVELOPER', false)
            }
        }
    },
    checkIsDonotfollow({ commit }: { commit: CommitFn<boolean> }): void {
        if (!navigator.cookieEnabled) {
            // TODO: Please enable cookies UI prompt
            console.warn("Please enable cookies");
        } else {
            const cookie = Cookies.get(DONOTFOLLOW_COOKIE);

            if (cookie) {
                commit('SET_IS_DONOTFOLLOW', true)
            } else {
                commit('SET_IS_DONOTFOLLOW', false)
            }
        }
    },
    unsetDonotfollowCookie({ commit }: { commit: CommitFn<boolean> }): void {
        if (!navigator.cookieEnabled) {
            // TODO: Please enable cookies UI prompt
            console.warn("Please enable cookies");
        } else {
            const cookie = Cookies.get(DONOTFOLLOW_COOKIE);

            if (cookie) {
                Cookies.remove(DONOTFOLLOW_COOKIE)
                commit('SET_IS_DONOTFOLLOW', false)
            }
        }
    },
    async fetchTrackingId({ commit }: { commit: CommitFn<string> }): Promise<void> {
        if (!navigator.cookieEnabled) {
            // TODO: Please enable cookies UI prompt
            console.warn("Please enable cookies");
        } else {
            const response = await analyticsApi.fetchTrackingId();

            if (response == null) {
                console.warn("developer cookie is set, not sending events to IPA");
                return;
            }

            document.cookie = response.data.cookie + "; Domain=oztix.com.au;";
            const trackingId = Cookies.get('inpage_cookie');
            if (trackingId) {
                commit('SET_TRACKING_ID', trackingId);
            }
        }
    },
    async fetchSessionData({ commit }: { commit: CommitFn<void | number[] | GeoInfo | string | { bands: BandKey[], categories: CategoryKey[], genres: GenreKey[] }> }): Promise<void> {
        const response = await api.session.getSessionData();
        commit('SET_BOOKMARKED_EVENTS', response.data.bookmarkedEventKeys || []);
        commit('SET_PREFERENCES', {
            bands: response.data.preferences?.bands || [],
            categories: response.data.preferences?.categories || [],
            genres: response.data.preferences?.genres || []
        });

        if (response.data.lastSetLocationType == LocationType.Geo) {
            // ATTN: Assumption that lastSetGeo is not null
            commit('SET_GEOLOCATION', response.data.lastSetGeo!);
        }

        if (response.data.lastSetLocationType == LocationType.Postcode) {
            // ATTN: Assumption that lastSetPostcode is not null
            commit('SET_POSTCODE', response.data.lastSetPostcode!);
        }

        commit('INCREMENT_VERSION');
    },
    // eslint-disable-next-line no-undef
    async setGeolocation({ commit, state }: { commit: CommitFn<GeoInfo>, state: SessionState }, { geolocation, interactionTarget }: { geolocation: GeolocationPosition, interactionTarget: string }): Promise<void> {
        const geo = { latitude: geolocation.coords.latitude, longitude: geolocation.coords.longitude };
        try {
            await api.session.postLocationUpdated({
                sessionId: state.sessionId,
                customerId: null, // TODO currently null, not saved to store
                interactionTarget,
                locationType: LocationType.Geo,
                geo
            });
        } catch (e) {
            console.warn("Could not set geolocation", e);
        }

        commit('SET_GEOLOCATION', geo);
    },
    async setPostcode({ commit, state }: { commit: CommitFn<string>, state: SessionState }, { postcode, interactionTarget }: { postcode: string, interactionTarget: string }): Promise<void> {
        try {
            await api.session.postLocationUpdated({
                sessionId: state.sessionId,
                customerId: null, // TODO currently null, not saved to store
                interactionTarget,
                locationType: LocationType.Postcode,
                postcode
            });
        } catch (e) {
            console.warn("Could not set postcode", e);
        }

        commit('SET_POSTCODE', postcode);
    },
    async unsetLocation({ commit, state }: { commit: CommitFn<void>, state: SessionState }, { interactionTarget }: { interactionTarget: string }): Promise<void> {
        try {
            await api.session.postLocationUpdated({
                sessionId: state.sessionId,
                customerId: null, // TODO currently null, not saved to store
                interactionTarget,
                locationType: LocationType.None
            });
        } catch (e) {
            console.warn("Could not send location update", e);
        }

        commit('UNSET_LOCATION');
    },
    async bookmarkEvent({ commit, state }: { commit: CommitFn<number>, state: SessionState }, { eventKey, interactionTarget }: { eventKey: number, interactionTarget: string }): Promise<void> {
        const eventIsBookmarked = state.bookmarkedEventKeys.includes(eventKey)

        if (eventIsBookmarked) {
            commit('UNBOOKMARK_EVENT', eventKey);
            gtag('event', 'unbookmark_event', {
                eventKey,
                interactionTarget
            });

            try {
                await api.session.deleteEventBookmarked({
                    sessionId: state.sessionId,
                    customerId: null, // TODO currently null, not saved to store
                    eventKey,
                    interactionTarget,
                });
            } catch (e) {
                console.warn("Could not delete bookmark", e);
            }
        } else {
            commit('BOOKMARK_EVENT', eventKey);
            gtag('event', 'bookmark_event', {
                eventKey,
                interactionTarget
            });

            try {
                await api.session.postEventBookmarked({
                    sessionId: state.sessionId,
                    customerId: null, // TODO currently null, not saved to store
                    eventKey,
                    interactionTarget,
                });
            } catch (e) {
                console.warn("Could not save bookmark", e);
            }
        }
    },
    async eventViewed({ state }: { state: SessionState }, { eventKey, interactionTarget }: { eventKey: number, interactionTarget: string }): Promise<void> {
        gtag('event', 'view_event', {
            eventKey,
            interactionTarget
        });

        if (config.SUPPRESS_VIEW_IMPRESSIONS === "false") {
            try {
                await api.interactionFact.postEventViewed({
                    sessionId: state.sessionId,
                    customerId: null, // TODO currently null, not saved to store
                    eventKey,
                    interactionTarget,
                });
            } catch (e) {
                console.warn("Could not send event viewed", e);
            }
        }
    },
    async eventClicked({ state }: { state: SessionState }, { eventKey, interactionTarget }: { eventKey: number, interactionTarget: string }): Promise<void> {
        gtag('event', 'click_event', {
            eventKey,
            interactionTarget
        });
        try {
            await api.interactionFact.postEventClicked({
                sessionId: state.sessionId,
                customerId: null, // TODO currently null, not saved to store
                eventKey,
                interactionTarget,
            });
        } catch (e) {
            console.warn("Could not send event clicked", e);
        }
    },
    async sectionViewed({ state }: { state: SessionState }, { interactionTarget }: { interactionTarget: string }): Promise<void> {
        gtag('event', 'view_section', {
            interactionTarget
        });
        if (config.SUPPRESS_VIEW_IMPRESSIONS === "false") {
            try {
                await api.interactionFact.postSectionViewed({
                    sessionId: state.sessionId,
                    customerId: null, // TODO currently null, not saved to store
                    interactionTarget,
                });
            } catch (e) {
                console.warn("Could not send section viewed", e);
            }
        }
    },
    async promotionClicked({ state }: { state: SessionState }, { interactionTarget }: { interactionTarget: string }): Promise<void> {
        gtag('event', 'click_promotion', {
            interactionTarget
        });

        /* TODO add warehouse interaction fact */
    },
    async sectionShowMore({ state }: { state: SessionState }, { interactionTarget }: { interactionTarget: string }): Promise<void> {
        gtag('event', 'showmore_section', {
            interactionTarget
        });
        try {
            await api.interactionFact.postSectionShowMoreClicked({
                sessionId: state.sessionId,
                customerId: null, // TODO currently null, not saved to store
                interactionTarget,
            });
        } catch (e) {
            console.warn("Could not send section show more", e);
        }
    },
    async submitPreferencesBands({ state, commit }: { state: SessionState, commit: CommitFn<Array<BandKey>> }, { interactionTarget, bands }: { interactionTarget: string, bands: Array<{ key: number; name: string; }> }): Promise<void> {
        const bandKeys = bands.map(b => b.key)
        try {
            await api.session.postPreferredBands({
                sessionId: state.sessionId,
                customerId: null, // TODO currently null, not saved to store
                interactionTarget,
                bands: bandKeys,
            });
        } catch (e) {
            console.warn("Could not send band preferences", e);
        }

        commit('SET_PREFERRED_BANDS', bandKeys);
    },
    async submitPreferencesCategories({ state, commit }: { state: SessionState, commit: CommitFn<Array<CategoryKey>> }, { interactionTarget, categories }: { interactionTarget: string, categories: Array<{ key: number; name: string; }> }): Promise<void> {
        const categoryKeys = categories.map(c => c.key)
        try {
            await api.session.postPreferredCategories({
                sessionId: state.sessionId,
                customerId: null, // TODO currently null, not saved to store
                interactionTarget,
                categories: categoryKeys,
            });
        } catch (e) {
            console.warn("Could not send categories preferences", e);
        }

        commit('SET_PREFERRED_CATEGORIES', categoryKeys);
    },
    async submitPreferencesGenres({ state, commit }: { state: SessionState, commit: CommitFn<Array<GenreKey>> }, { interactionTarget, genres }: { interactionTarget: string, genres: Array<{ key: number; name: string; }> }): Promise<void> {
        const genreKeys = genres.map(g => g.key);
        try {
            await api.session.postPreferredGenres({
                sessionId: state.sessionId,
                customerId: null, // TODO currently null, not saved to store
                interactionTarget,
                genres: genreKeys
            });
        } catch (e) {
            console.warn("Could not send genres preferences", e);
        }

        commit('SET_PREFERRED_GENRES', genreKeys);
    },
}

// mutations
const mutations = {
    INCREMENT_VERSION(state: SessionState): void {
        state.version++;
    },
    SET_SESSION_ID(state: SessionState, payload: string): void {
        state.sessionId = payload;
    },
    SET_IS_DEVELOPER(state: SessionState, payload: boolean): void {
        state.isDeveloper = payload;
    },
    SET_IS_DONOTFOLLOW(state: SessionState, payload: boolean): void {
        state.isDonotfollow = payload;
    },
    SET_TRACKING_ID(state: SessionState, payload: string): void {
        state.trackingId = payload;
    },
    SET_BOOKMARKED_EVENTS(state: SessionState, payload: number[]): void {
        state.bookmarkedEventKeys = payload;
    },
    SET_GEOLOCATION(state: SessionState, payload: null | GeoInfo): void {
        state.lastSetLocationType = payload === null ? LocationType.None : LocationType.Geo;
        state.lastSetGeo = payload;
        state.lastSetLocationVersion++;
    },
    SET_POSTCODE(state: SessionState, payload: null | string): void {
        state.lastSetLocationType = payload === null ? LocationType.None : LocationType.Postcode;
        state.lastSetPostcode = payload;
        state.lastSetLocationVersion++;
    },
    UNSET_LOCATION(state: SessionState): void {
        state.lastSetLocationType = LocationType.None;
        state.lastSetPostcode = null;
        state.lastSetGeo = null;
        state.lastSetLocationVersion++;
    },
    BOOKMARK_EVENT(state: SessionState, payload: number): void {
        if (state.bookmarkedEventKeys.indexOf(payload) > -1) return;
        state.bookmarkedEventKeys.push(payload);
    },
    UNBOOKMARK_EVENT(state: SessionState, payload: number): void {
        const indexOfBookmark = state.bookmarkedEventKeys.indexOf(payload);
        if (indexOfBookmark < 0) return; // event not there, maybe a race condition

        state.bookmarkedEventKeys.splice(indexOfBookmark, 1); //TODO - why is this working? i.e. re-rendering
    },
    SET_PREFERENCES(state: SessionState, payload: { bands: Array<BandKey>, categories: Array<CategoryKey>, genres: Array<GenreKey> }): void {
        state.preferences.bands = payload.bands;
        state.preferences.categories = payload.categories;
        state.preferences.genres = payload.genres;
        state.preferencesVersion++;
    },
    SET_PREFERRED_BANDS(state: SessionState, payload: Array<BandKey>): void {
        state.preferences.bands = payload;
        state.preferencesVersion++;
    },
    SET_PREFERRED_CATEGORIES(state: SessionState, payload: Array<CategoryKey>): void {
        state.preferences.categories = payload;
        state.preferencesVersion++;
    },
    SET_PREFERRED_GENRES(state: SessionState, payload: Array<GenreKey>): void {
        state.preferences.genres = payload;
        state.preferencesVersion++;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


import { defineComponent } from "vue";
import OztixLogo from "@/components/Logo/OztixLogo.vue";
import { Copyright } from "@/components";

export default defineComponent({
  name: "TheFooter",
  components: {
    OztixLogo,
    Copyright,
  },
  methods: {
    ticketYourEventClick() {
      this.$posthog?.capture('website:ticket_your_event_link_click', {
        location: 'footer',
        brand: 'Oztix'
      });
    }
  }
});

import { api } from "@/api"
import { DeveloperOverrides, RecommendationsOptions, ViewEvent } from "@/api/types"
import { Section } from "@/components/Section/types"
import { CommitFn } from "@/store/types"
import { mapCatalogToEventsInSection, randomiseFirstTenEvents } from "./recommendationsHelpers"

export type RecommendationsState = {
    version: number; // since fetching recommendations is a slow process, and maybe called multiple times, we only care about the response of the latest call.
    sections: null | Section[];
    catalog: null | ViewEvent[];
    displayedLocation: string;
    status: 'pending' | 'success' | 'failure';
}

//initial state
const state = (): RecommendationsState => ({
    version: 0,
    sections: null,
    catalog: null,
    displayedLocation: '',
    status: 'pending'
})

// getters
const getters = {}

// actions
const actions = {
    async fetchRecommendations({ commit, state }: { commit: CommitFn<Section[] | ViewEvent[] | string | void>, state: RecommendationsState }, { options, developerOverrides }: { options: RecommendationsOptions, developerOverrides: DeveloperOverrides }): Promise<void> {
        commit('SET_SECTIONS', [])
        commit('SET_CATALOG', [])
        commit('INCREMENT_VERSION')
        commit('RECOMMENDATIONS_POST_PENDING')

        const currentVersion = state.version;

        try {
            const response = await api.recommendation.postRecommendations(
                options,
                developerOverrides
            );

            if (currentVersion != state.version) {
                return; // a newer API call is in progress
            }

            const sections = [];

            for (let i = 0; i < response.data.sections.length; i++) {
                const s = response.data.sections[i];
                if (s.events.length == 0) continue;

                const section: Section = {
                    title: s.title,
                    anchorLink: s.id,
                    events: mapCatalogToEventsInSection(s,
                        response.data.catalog),
                };

                randomiseFirstTenEvents(section);

                sections.push(section);
            }

            commit('SET_SECTIONS', sections)
            commit('SET_CATALOG', response.data.catalog)
            commit('RECOMMENDATIONS_POST_SUCCESS')
            commit('SET_DISPLAYED_LOCATION', response.data.displayedLocation)
        } catch (error) {
            commit('RECOMMENDATIONS_POST_FAILURE')
        }
    }
}

// mutations
const mutations = {
    SET_SECTIONS(state: RecommendationsState, payload: Section[]): void {
        state.sections = payload
    },
    SET_CATALOG(state: RecommendationsState, payload: ViewEvent[]): void {
        state.catalog = payload
    },
    SET_DISPLAYED_LOCATION(state: RecommendationsState, paylaod: string): void {
        state.displayedLocation = paylaod
    },
    INCREMENT_VERSION(state: RecommendationsState): void {
        state.version++
    },
    RECOMMENDATIONS_POST_FAILURE(state: RecommendationsState): void {
        state.status = 'failure'
    },
    RECOMMENDATIONS_POST_SUCCESS(state: RecommendationsState): void {
        state.status = 'success'
    },
    RECOMMENDATIONS_POST_PENDING(state: RecommendationsState): void {
        state.status = 'pending'
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


import { defineComponent } from "vue";
import { IconLocation } from "@/components";
import BaseDataCaptureSection from "@/components/Section/BaseDataCaptureSection.vue";
import LocationModal from "@/components/Modal/LocationModal.vue";

export default defineComponent({
  name: "GetLocationSection",
  components: {
    IconLocation,
    BaseDataCaptureSection,
    LocationModal,
  },
  emits: ["locationSetGeo", "locationSetPostcode", "locationUnset"],
  props: {
    isLocationSet: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});

export const detailedContent = {
    ourExperience: {
        heading: "Our Experience",
        text: "We're way more than just a ticketing company, we’re your one-way ticket to a brighter future.",
        details: [
            {
                heading: "Our Service",
                id: "our-service",
                icon: "icon-our-service.png",
                text: "We care. We don't outsource to distant lands, we pick up the phone when you call, and we provide the high level of service you'd expect from any independent local company worth their salt."
            },
            {
                heading: "Event Management",
                id: "event-management",
                icon: "icon-event-management.png",
                text: "We are experienced event managers. From the first interaction with a customer buying tickets to them entering the front gate, we take care of the ticketing and help you focus on what you know best, your event. We can assist with site and production management, OHS, government regulatory compliance, entertainment, food and markets, anything you need for an event, we have done it before."
            },
            {
                heading: "Our Networks",
                id: "our-networks",
                icon: "icon-our-networks.png",
                text: "Connect with our national network of partners to deliver an event that exceeds all expectations. From staging to payment gateways, venue choice to talent procurement, we've got you covered."
            },
            {
                heading: "Decades of Experience",
                id: "decades-of-experience",
                icon: "icon-decades-of-experience.png",
                text: "With a company formed by well-respected Directors who have a combined 50+ years’ experience delivering high-profile and large-scale events, coupled with 18+ years of operation - nobody gets the events game more than we do."
            }
        ]

    },
    ourMarketing: {
        heading: "Our Marketing",
        text: "Increase awareness and sell more tickets to your event by utilising our market leading technology and in-house data and marketing experts.",
        details: [
            {
                heading: "Customer Database",
                id: "customer-database",
                icon: "icon-customer-database.png",
                text: "We built a database of people who buy tickets to events, and we know exactly what they like."
            },
            {
                heading: "Event Responsive Marketing",
                id: "event-responsive-marketing",
                icon: "icon-event-responsive-marketing.png",
                text: "Our powerful marketing engine has been custom-built to understand every detail of the event lifecycle. Our Event Responsive Marketing never sleeps. Announcement, pre-registration, on sale, remarketing, reminders and waitlists provide a personalised experience to the right customer, at the right time."
            },
            {
                heading: "Personalisation",
                id: "personalisation",
                icon: "icon-technology.png",
                text: "Connect your events with our customers. Oztix Personalisation is a highly optimised marketing channel that works to sell your events. Personalisation looks at every on-sale Oztix event and via a series of intuitive algorithms connects them with customers we know will be interested through our newsletter and homepage."
            },
            {
                heading: "Marketing Professionals",
                id: "marketing-professionals",
                icon: "icon-social-media.png",
                text: "We're your partner for growth and our goal is to help you sell more tickets. Connect with an Oztix Marketing specialist, skilled in creating and delivering marketing for your event whilst providing genuine support and insider knowledge to help you start selling tickets."
            }
        ]
    },
    ourPlatform: {
        heading: "Our Platform",
        text: "At the core of Oztix is one of the most powerful ticketing platforms in the world. Built right here in Australia by a team of passionate developers who live and breathe events.",
        details: [
            {
                heading: "Manage Your Event",
                id: "manage-your-event",
                icon: "icon-manage-your-event.png",
                text: "From self-service to full-service, or something in-between. Create, update, and sell your events anytime, anywhere, within minutes."
            },
            {
                heading: "Understand Your Event",
                id: "understand-your-event",
                icon: "icon-understand-your-event.png",
                text: "Better understand your marketing efforts, where your customers come from, and who they are. Our state-of-the-art Insights platform gives you total transparency over whether the money you spend and partnerships you push convert to ticket sales and interest in your event."
            },
            {
                heading: "On-Site at Your Event",
                id: "on-site-at-your-event",
                icon: "icon-on-site-at-your-event.png",
                text: "Our On-site Technology was mindfully developed to ensure our system never stops. Rain, hail, Wi-Fi issues, or whatever you want to throw at it. We make sure the show goes on, no matter what."
            },
            {
                heading: "Integrations for Your Event",
                id: "integrations-for-your-event",
                icon: "icon-integrations-for-your-event.png",
                text: "Some companies do some things better than us, which is why we integrate with them! We do what we do best, and find others to do the same, meaning you're always working with the best in the game."
            }
        ]
    }
}

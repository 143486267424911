type Web2CaseFormData = {
    [key: string]: string;
    orgid: string;
    recordType: string;
    retURL: string;
    name: string;
    email: string;
    subject: string;
    type: string;
    Case_Reason__c: string;
    selectedEvent: string;
    description: string;
}

export const web2Case = function (fields: Web2CaseFormData): void {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8";
    for (const fieldName in fields) {
        const theInput = document.createElement("input");
        theInput.name = fieldName;
        theInput.value = fields[fieldName];
        theInput.setAttribute("type", "hidden");
        form.appendChild(theInput);
    }
    document.body.appendChild(form);
    form.submit();
}
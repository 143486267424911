
import { defineComponent, PropType } from "vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { OztixEvent } from "@/domain/types";
import { HeroEvent } from "@/components";
import LocationModal from "@/components/Modal/LocationModal.vue"; // Don't know why it doesn't work importing from "@/components"
import { mapState } from "vuex";

export default defineComponent({
  name: "HeroSection",
  components: {
    Carousel,
    Navigation,
    Pagination,
    Slide,
    HeroEvent,
    LocationModal,
  },
  emits: [
    "sectionViewed",
    "locationSetGeo",
    "locationSetPostcode",
    "locationUnset",
  ],
  props: {
    title: {
      type: String,
      required: true,
    },
    anchorLink: {
      type: String,
      required: false,
      default: "anchor",
    },
    events: {
      type: Array as PropType<Array<OztixEvent>>,
      required: false,
      default: () => [],
    },
    maxEvents: {
      type: Number as PropType<number>,
      required: false,
      default: 20,
    },
    isLocationSet: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    const carousel = {
      settings: {
        itemsToShow: 1,
        snapAlign: "start",
      },
      breakpoints: {
        650: {
          itemsToShow: 2,
          snapAlign: "start",
        },
        1000: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
    return {
      observer: null as null | IntersectionObserver,
      carousel,
    };
  },
  computed: {
    ...mapState("session", ["bookmarkedEventKeys"]),
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      threshold: [0, 0.9],
    });

    this.$nextTick(() => {
      if (
        this.$refs.container instanceof Element &&
        this.observer instanceof IntersectionObserver
      ) {
        this.observer.observe(this.$refs.container);
      }
    });
  },
  methods: {
    onEventViewed(eventKey: number, index: number) {
      this.$store.dispatch("session/eventViewed", {
        eventKey: eventKey,
        interactionTarget: `hero-section--${this.anchorLink}--index#${index}`,
      });
    },
    onBookmarkEvent(eventKey: number, index: number) {
      this.$store.dispatch("session/bookmarkEvent", {
        eventKey: eventKey,
        interactionTarget: `hero-section--${this.anchorLink}--index#${index}`,
      });
    },
    onEventClicked(eventKey: number, index: number) {
      this.$store.dispatch("session/eventClicked", {
        eventKey: eventKey,
        interactionTarget: `hero-section--${this.anchorLink}--index#${index}`,
      });
    },
    handleIntersection(entries: IntersectionObserverEntry[]) {
      for (const { isIntersecting, intersectionRatio } of entries) {
        if (!isIntersecting) {
          return;
        }

        if (intersectionRatio > 0.8) {
          this.$emit("sectionViewed");
        }
      }
    },
  },
});


import { defineComponent } from "vue";
import { IconBand } from "@/components";
import BaseDataCaptureSection from "@/components/Section/BaseDataCaptureSection.vue";
import DataCaptureModal from "@/components/Modal/DataCaptureModal.vue";

export default defineComponent({
  name: "GetBandsSection",
  components: {
    IconBand,
    BaseDataCaptureSection,
    DataCaptureModal,
  },
  emits: ["submitPreferences", "resetPreferences"],
});

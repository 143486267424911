
import { defineComponent } from "vue";
import { SkipLink } from "@/components";
import { heroContent } from "@/content/b2b/hero-content";

export default defineComponent({
  name: "B2bHero",
  components: {
    SkipLink
  },
  data() {
    return {
      content: heroContent
    };
  },
  methods: {
    createEventClick() {
      const posthogDistinctId = this.$posthog ? this.$posthog.get_distinct_id() : '';

      this.$posthog?.capture('website:create_event_link_click', {
        location: 'ticket_your_event_hero',
        brand: 'Oztix'});

      setTimeout(() => {
        window.location.href = this.content.primaryCta.link(posthogDistinctId);
      }, 500);
    }
  }
});

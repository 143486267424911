
import { defineComponent } from "vue";
import CompactLayout from "@/layouts/CompactLayout.vue";
import { IconHeart } from "@/components";

export default defineComponent({
  components: {
    CompactLayout,
    IconHeart,
  },
});

import { OztixEvent } from "@/domain/types";

export const sampleEvents: Array<OztixEvent> = [{
    eventKey: 0,
    name: 'Annie At The Helm',
    url: '#',
    where:'Oztix Arena',
    when: 'Fri 11 Mar 2021',
    image: 'https://assets.oztix.com.au/image/f2a28f18-1bb2-4374-b1c8-ef8b1cab84a3.png',
    bookmarked: false
},
{
    eventKey: 0,
    name: 'Hard Luck Champions',
    url: '#',
    where:'Oztix Stadium',
    when: 'Sat 3 Sep 2022',
    image: 'https://assets.oztix.com.au/image/426e9cb0-c527-4fff-aa60-0779461117ef.png',
    bookmarked: false
},
{
    eventKey: 0,
    name: 'Fire & Whistle Theory',
    url: '#',
    where:'Oztix Tavern',
    when: 'Sun 18 Dec 2021',
    image: 'https://assets.oztix.com.au/image/d6e5584a-7d1f-450a-ace3-752d46c48832.png',
    bookmarked: false
},
{
    eventKey: 0,
    name: 'Hard Luck Champions',
    url: '#',
    where:'Oztix Tavern',
    when: 'Sat 9 Oct 2020',
    image: 'https://assets.oztix.com.au/image/36e5ebef-0825-4e97-a16d-3993700295b7.png',
    bookmarked: false
},
{
    eventKey: 0,
    name: 'Good Things 2019',
    url: '#',
    where:'Oztix Garage',
    when: 'Sat 14 Dec 2019',
    image: 'https://assets.oztix.com.au/image/aba1b256-672c-4356-b412-c12a12b25312.png',
    bookmarked: false
},
{
    eventKey: 0,
    name: 'My awesome event',
    url: '#',
    where:'Oztix Tavern',
    when: 'Sat 23 Oct 2021',
    image: 'https://via.placeholder.com/350x150',
    bookmarked: false
}];
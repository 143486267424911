import { renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "stack",
  ref: "container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_images_loaded = _resolveDirective("images-loaded")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default")
  ], 512)), [
    [_directive_images_loaded, _ctx.onImagesLoaded]
  ])
}
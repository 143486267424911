import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "simple-modal_message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "button-label": _ctx.buttonLabel,
    "modal-heading": _ctx.modalHeading,
    "initial-focus-id": `#${_ctx.initialFocusId}`
  }, {
    contents: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.message), 1)
    ]),
    _: 1
  }, 8, ["button-label", "modal-heading", "initial-focus-id"]))
}
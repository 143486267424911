import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f0b8c6b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "btn" }
const _hoisted_2 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_close = _resolveComponent("icon-close")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createVNode(_component_icon_close, {
      "has-focus": _ctx.hasFocus,
      "has-hover": _ctx.hasHover
    }, null, 8, ["has-focus", "has-hover"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1)
  ], 512))
}